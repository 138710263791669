import React from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

interface AreaChartRechartsProps {
  data: any;
  xKey: string;
  yKeys: string[];
  colors: string[];
}

export const AreaChartRecharts: React.FC<AreaChartRechartsProps> = ({
  data,
  xKey,
  yKeys,
  colors,
}) => {
  return (
    <ResponsiveContainer width="100%" height={300}>
      <AreaChart
        data={data}
        margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey={xKey} />
        <YAxis />
        <Tooltip />
        {yKeys.map((yKey, index) => (
          <Area
            key={yKey}
            type="monotone"
            dataKey={yKey}
            stroke={colors[index]}
            fill={colors[index]}
          />
        ))}
      </AreaChart>
    </ResponsiveContainer>
  );
};
