import React from "react";
import styles from "./DataBox.module.css";
import { Loader, MessageSquare, Send } from "lucide-react";
import { SearchBarBetween } from "../../searchbars/SearchBarBetween/SearchBarBetween";
import { ButtonIcon } from "../../buttons/ButtonIcon/ButtonIcon";
import {
  Area,
  AreaChart,
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  ComposedChart,
  Legend,
  Line,
  LineChart,
  PieChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { Pie } from "recharts";
import { colors } from "../../../constants/colors";
import { ModelDropdown } from "../../dropdowns/ModelDropdown/ModelDropdown";
import { getChartKeys } from "../../../utils/charts/getChartKeys";

interface DataBoxProps {
  onClick?: () => void;
  inputText: string;
  setInputText: (value: string) => void;
  data?: any;
  buttonDisabled?: boolean;
  models: any[];
  selectedModel: string;
  setSelectedModel: (model: string) => void;
}

export const DataBox: React.FC<DataBoxProps> = ({
  onClick,
  inputText,
  setInputText,
  data,
  buttonDisabled = false,
  models,
  selectedModel,
  setSelectedModel,
}) => {
  const parseData = () => {
    if (!data) return null;
    try {
      if (typeof data === "object") {
        return data;
      }

      if (typeof data === "string") {
        const jsonMatch = data.match(/```json\n([\s\S]*?)\n```/);

        if (jsonMatch && jsonMatch[1]) {
          return JSON.parse(jsonMatch[1].trim());
        }

        try {
          return JSON.parse(data);
        } catch {
          return data;
        }
      }

      return null;
    } catch (error) {
      console.error("Error parsing data:", error);
      return null;
    }
  };

  const parsedData = parseData();

  return (
    <div className={styles.box}>
      <div className={styles.header}>
        <MessageSquare size={20} />
        <h3>Data Intelligence Assistant</h3>
      </div>
      <div className={styles.searchBarSpace}>
        <SearchBarBetween
          placeholder="Ask about stockout patterns, trends, or request business advice..."
          inputText={inputText}
          setInputText={setInputText}
        />
        <ModelDropdown
          models={models}
          selectedModel={selectedModel}
          onSelect={(model: any) => {
            setSelectedModel(model);
          }}
        />
        <ButtonIcon
          icon={
            !buttonDisabled ? (
              <Send size={16} />
            ) : (
              <Loader size={16} className="loader-animate" />
            )
          }
          onClick={onClick}
          disabled={buttonDisabled}
        >
          Ask
        </ButtonIcon>
      </div>
      <div className={styles.contentContainer}>
        {buttonDisabled ? (
          <div className={styles.loading}>
            <Loader size={64} className="loader-animate" />
          </div>
        ) : (
          parsedData && (
            <>
              {parsedData?.summary && (
                <div className={styles.summary}>
                  <h4>Summary</h4>
                  <p>{parsedData?.summary}</p>
                  <h4>Key Findings</h4>
                  <ul>
                    {parsedData?.key_findings?.map(
                      (finding: any, index: number) => (
                        <li key={index}>
                          {typeof finding === "object" ? finding?.[0] : finding}
                        </li>
                      )
                    )}
                  </ul>
                </div>
              )}

              <div className={styles.visualizations}>
                {parsedData?.data_visualizations?.map(
                  (viz: any, index: number) => {
                    const { xKey, yKeys } = getChartKeys(viz?.data || []);

                    return (
                      <div key={index} className={styles.chart}>
                        <h4>{viz.name}</h4>
                        {viz?.type === "PieChart" && (
                          <ResponsiveContainer width="100%" height={300}>
                            <PieChart>
                              <Pie
                                data={viz?.data}
                                dataKey={yKeys[0] || "value"}
                                nameKey={xKey || "category"}
                                cx="50%"
                                cy="50%"
                                fill="#8884d8"
                              >
                                {viz?.data?.map((entry: any, index: number) => (
                                  <Cell
                                    key={`cell-${index}`}
                                    fill={colors[index % colors.length]}
                                  />
                                ))}
                              </Pie>
                              <Tooltip />
                              <Legend />
                            </PieChart>
                          </ResponsiveContainer>
                        )}
                        {viz?.type === "ComposedChart" && (
                          <ResponsiveContainer width="100%" height={300}>
                            <ComposedChart data={viz?.data}>
                              <CartesianGrid stroke="#f5f5f5" />
                              <XAxis dataKey={xKey || "period"} />
                              <YAxis />
                              <Tooltip />
                              <Legend />
                              <Area
                                type={"monotone"}
                                dataKey={yKeys[0]}
                                fill="#8884d8"
                              />
                              <Bar
                                dataKey={yKeys[1]}
                                barSize={20}
                                fill="#413ea0"
                              />
                              <Line
                                type="monotone"
                                dataKey={yKeys[2]}
                                stroke="#ff7300"
                              />
                            </ComposedChart>
                          </ResponsiveContainer>
                        )}
                        {viz?.type === "LineChart" && (
                          <ResponsiveContainer width="100%" height={300}>
                            <LineChart data={viz?.data}>
                              <CartesianGrid strokeDasharray="3 3" />
                              <XAxis dataKey={xKey || "period"} />
                              <YAxis />
                              <Tooltip />
                              <Legend />
                              {yKeys.map((key: string, idx: number) => (
                                <Line
                                  key={key}
                                  type="monotone"
                                  dataKey={key || "value"}
                                  stroke={colors[idx % colors.length]}
                                />
                              ))}
                            </LineChart>
                          </ResponsiveContainer>
                        )}
                        {viz?.type === "AreaChart" && (
                          <ResponsiveContainer width="100%" height={300}>
                            <AreaChart data={viz?.data}>
                              <CartesianGrid strokeDasharray="3 3" />
                              <XAxis dataKey={xKey || "period"} />
                              <YAxis />
                              <Tooltip />
                              <Legend />
                              {yKeys.map((key: string, idx: number) => (
                                <Line
                                  key={key}
                                  type="monotone"
                                  dataKey={key || "value"}
                                  stroke={colors[idx % colors.length]}
                                />
                              ))}
                            </AreaChart>
                          </ResponsiveContainer>
                        )}
                        {viz?.type === "BarChart" && (
                          <ResponsiveContainer width="100%" height={300}>
                            <BarChart data={viz?.data}>
                              <CartesianGrid strokeDasharray="3 3" />
                              <XAxis dataKey={xKey || "category"} />
                              <YAxis />
                              <Tooltip />
                              <Legend />
                              {yKeys.map((key: string, idx: number) => (
                                <Bar
                                  key={key}
                                  dataKey={key || "current"}
                                  fill={colors[idx % colors.length]}
                                />
                              ))}
                            </BarChart>
                          </ResponsiveContainer>
                        )}
                      </div>
                    );
                  }
                )}
              </div>

              {parsedData?.detailed_analysis?.description && (
                <div className={styles.analysis}>
                  <h4>Detailed Analysis</h4>
                  <p>{parsedData?.detailed_analysis?.description}</p>
                  <h4>Recommendations</h4>
                  <ul>
                    {parsedData?.detailed_analysis?.recommendations?.map(
                      (rec: any, index: number) => (
                        <li key={index}>
                          {typeof rec === "object" ? rec?.[0] : rec}
                        </li>
                      )
                    )}
                  </ul>
                </div>
              )}
            </>
          )
        )}
      </div>
    </div>
  );
};
