// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DataBox_box__5SdPI {
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  border: 1px solid #ebecef;
  border-radius: 12px;
  background-color: #fff;
  width: 100%;
}

.DataBox_header__hu48N {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
}

.DataBox_searchBarSpace__Hs1-9 {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.DataBox_chips__L\\+5k- {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  margin-top: 8px;
}

@media (min-width: 768px) {
  .DataBox_searchBarSpace__Hs1-9 {
    flex-direction: row;
    align-items: center;
  }

  .DataBox_box__5SdPI {
    padding: 24px;
    gap: 24px;
  }

  .DataBox_chips__L\\+5k- {
    gap: 8px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/boxes/DataBox/DataBox.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,yBAAyB;EACzB,mBAAmB;EACnB,sBAAsB;EACtB,WAAW;AACb;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,QAAQ;EACR,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,QAAQ;AACV;;AAEA;EACE,aAAa;EACb,eAAe;EACf,QAAQ;EACR,eAAe;AACjB;;AAEA;EACE;IACE,mBAAmB;IACnB,mBAAmB;EACrB;;EAEA;IACE,aAAa;IACb,SAAS;EACX;;EAEA;IACE,QAAQ;EACV;AACF","sourcesContent":[".box {\n  padding: 16px;\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n  border: 1px solid #ebecef;\n  border-radius: 12px;\n  background-color: #fff;\n  width: 100%;\n}\n\n.header {\n  display: flex;\n  flex-direction: row;\n  gap: 8px;\n  align-items: center;\n}\n\n.searchBarSpace {\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n}\n\n.chips {\n  display: flex;\n  flex-wrap: wrap;\n  gap: 4px;\n  margin-top: 8px;\n}\n\n@media (min-width: 768px) {\n  .searchBarSpace {\n    flex-direction: row;\n    align-items: center;\n  }\n\n  .box {\n    padding: 24px;\n    gap: 24px;\n  }\n\n  .chips {\n    gap: 8px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": `DataBox_box__5SdPI`,
	"header": `DataBox_header__hu48N`,
	"searchBarSpace": `DataBox_searchBarSpace__Hs1-9`,
	"chips": `DataBox_chips__L+5k-`
};
export default ___CSS_LOADER_EXPORT___;
