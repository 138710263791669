export const getChartKeys = (data: any[]) => {
  if (!data || data.length === 0) return { xKey: "", yKeys: [] };

  const keys = Object.keys(data?.[0]);
  const xKey =
    keys?.find(
      (key) =>
        typeof data?.[0]?.[key] === "string" || data?.[0]?.[key] instanceof Date
    ) || keys?.[0];

  const yKeys = keys.filter(
    (key) => key !== xKey && typeof data?.[0]?.[key] === "number"
  );

  return { xKey, yKeys };
};
