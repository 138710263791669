import React, { useEffect, useState } from "react";
import "./Home.css";
import {
  DollarSign,
  Package,
  ShoppingCart,
  Star,
  TrendingUp,
} from "lucide-react";
import { SearchBar } from "../../components/searchbars/SearchBar/SearchBar";
import { CategoryBox } from "../../components/boxes/CategoryBox/CategoryBox";
import { DataBox } from "../../components/boxes/DataBox/DataBox";
import { chips, qas } from "../../constants/general";
import { FeaturedKpiBox } from "../../components/boxes/FeaturedKpiBox/FeaturedKpiBox";
import { Loading } from "../Loading";
import {
  financialHealthSeparate,
  operationalEfficiencySeparate,
  salesRevenueSeparate,
  supplyChainSeparate,
} from "../../utils/main/separateCategories";
import { icons } from "../../utils/main/icons";
import { getColor } from "../../utils/main/generateColor";
import { useDnasMutation } from "../../api/ios.api";
import { DialogPopup } from "../../components/dialogs/DialogPopup/DialogPopup";
import { dialogContent } from "../../constants/dialogContent";

export const Home: React.FC = () => {
  const [dnas, setDnas] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [filteredDnas, setFilteredDnas] = useState<any[]>([]);
  const [searchValue, setSearchValue] = useState<string>("");
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedDialogContent, setSelectedDialogContent] = useState<any>(null);

  const [ios] = useDnasMutation();

  useEffect(() => {
    const fetchKpis = async () => {
      try {
        const response = await ios();
        if ("data" in response) {
          setDnas(response.data);
          setFilteredDnas(response.data);
        } else {
          console.error("Error fetching kpis:", response.error);
        }
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchKpis();
  }, [ios]);

  useEffect(() => {
    const filtered = dnas.filter((dna) =>
      dna.name.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredDnas(filtered);
  }, [searchValue, dnas]);

  if (loading) {
    return <Loading />;
  }

  const toggleDialog = (name?: string) => {
    if (name) {
      const content = dialogContent.find((item) => item.name === name);
      setSelectedDialogContent(content || null);
    }
    setIsDialogOpen(!isDialogOpen);
  };

  return (
    <div className="Page">
      <div className="Header-Home">
        <h1>KPI Analytics Hub</h1>
        <SearchBar
          placeholder="Search KPIs by name, description, or category..."
          onSearch={setSearchValue}
          results={filteredDnas.map((dna) => ({
            id: dna.id,
            name: dna.name,
            type: dna.type,
          }))}
        />
      </div>

      <div className="Kpis">
        <div className="FeaturedKPIs">
          <div className="FeaturedKPIsTitle">
            <Star size={24} color="#F3E2AA" style={{ cursor: "pointer" }} />
            <h2>Featured KPIs</h2>
          </div>
          {dnas.length !== 0 &&
            dnas
              .slice(0, 3)
              .map((dna, index) => (
                <FeaturedKpiBox
                  key={index}
                  icon={icons[index % icons.length]}
                  title={dna?.name}
                  description={dna?.description}
                  type={dna?.type}
                  visualization={dna?.visualization}
                  factors={dna?.external_factors}
                  color={getColor(index).color}
                  border={getColor(index).border}
                  link={`/dna/${dna?.name}`}
                  onClick={() => toggleDialog(dna?.name)}
                />
              ))}
        </div>

        <CategoryBox
          title="Financial Health"
          icon={<DollarSign size={20} color="#16A34A" />}
          kpis={financialHealthSeparate(dnas)}
          onClick={(name) => toggleDialog(name)}
        />
        <CategoryBox
          title="Operational Efficiency"
          icon={<TrendingUp size={20} color="#2563EB" />}
          kpis={operationalEfficiencySeparate(dnas)}
          onClick={(name) => toggleDialog(name)}
        />
        <CategoryBox
          title="Supply Chain"
          icon={<Package size={20} color="#A04BEC" />}
          kpis={supplyChainSeparate(dnas)}
          onClick={(name) => toggleDialog(name)}
        />
        <CategoryBox
          title="Sales & Revenue"
          icon={<ShoppingCart size={20} color="#EA5F17" />}
          kpis={salesRevenueSeparate(dnas)}
          onClick={(name) => toggleDialog(name)}
        />

        <DataBox qas={qas} chips={chips} />
      </div>
      <DialogPopup
        isOpen={isDialogOpen}
        onClose={toggleDialog}
        data={selectedDialogContent}
      />
    </div>
  );
};
