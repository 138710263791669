import React from "react";
import styles from "./Table.module.css";

export interface TableProps {
  thead: { [key: string]: string };
  tbody: Array<{ [key: string]: string }>;
}

export const Table: React.FC<TableProps> = ({ thead, tbody }) => {
  const columns = Object.keys(thead);

  return (
    <table className={styles.table}>
      <thead>
        <tr>
          {columns.map((key) => (
            <th key={key}>{thead[key]}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {tbody.map((row, rowIndex) => (
          <tr key={rowIndex}>
            {columns.map((key) => (
              <td key={key}>{row[key]}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};
