import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { OLLAMA_BASE_URL } from "./constant/const";
import { GenerateRequest } from "../dtos/GenerateRequest.dto";

export const ollamaApi = createApi({
  reducerPath: "ollamaApi",
  baseQuery: fetchBaseQuery({
    baseUrl: OLLAMA_BASE_URL + "/api",
  }),
  endpoints: (build) => ({
    generateResponse: build.mutation<any, GenerateRequest>({
      query: ({ prompt, model }) => ({
        url: "/generate",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: {
          prompt,
          model,
          stream: false,
        },
      }),
    }),
    tags: build.mutation<any, void>({
      query: () => ({
        url: "/tags",
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      }),
    }),
  }),
});

export const { useGenerateResponseMutation, useTagsMutation } = ollamaApi;
