import { ChartBox } from "../../components/boxes/ChartBox/ChartBox";
import { AreaChartRecharts } from "../../components/charts/AreaChart/recharts/AreaChartRecharts";
import { BarChartNivo } from "../../components/charts/BarChart/nivo/BarChartNivo";
import { FunnelChartNivo } from "../../components/charts/FunnelChart/nivo/FunnelChartNivo";
import { GanttChartNivo } from "../../components/charts/GanttChart/nivo/GanttChartNivo";
import { GeographicMap } from "../../components/charts/GeographicMap/GeographicMap";
import { HeatmapNivo } from "../../components/charts/Heatmap/nivo/HeatmapNivo";
import { LineChartRecharts } from "../../components/charts/LineChart/recharts/LineChartRecharts";
import { PieChartNivo } from "../../components/charts/PieChart/nivo/PieChartNivo";
import { SankeyNivo } from "../../components/charts/SankeyDiagram/nivo/SankeyNivo";
import { ScatterPlotRecharts } from "../../components/charts/ScatterPlot/recharts/ScatterPlotRecharts";
import { StackedBarChartNivo } from "../../components/charts/StackedBarChart/nivo/StackedBarChartNivo";
import { WaterfallRecharts } from "../../components/charts/WaterfallChart/recharts/WaterfallRecharts";
import { colors } from "../../constants/colors";
import { chartDataConverter, chartDataConverterV2 } from "./chartDataConverter";
import { formatDataForCharts } from "./formatDataForCharts";

export const renderCharts = (visual: string, chartData: any, type: string) => {
  const chartTypes = visual?.split(";");

  const convertedChartData =
    type === "KPI"
      ? chartDataConverter(chartData)
      : chartDataConverterV2(chartData);

  const formattedData = formatDataForCharts(convertedChartData);
  const keys = formattedData?.[0]
    ? Object.keys(formattedData[0]).filter((key) => key !== "month")
    : [];

  const filterExistingKeys = (keys: string[]) => {
    const excludedKeys = ["startDate", "endDate", "name"];

    return keys.filter((key) =>
      convertedChartData?.some(
        (item: any) => !excludedKeys?.includes(key) && item[key] !== undefined
      )
    );
  };

  const pieData = formattedData.map((item: any) => ({
    id: item.month,
    value: item.avg_revenue_per_deal,
  }));

  return chartTypes?.map((chartType, index) => {
    switch (chartType?.trim()) {
      case "Line Chart":
        return (
          <ChartBox
            key={index}
            title="Line Chart"
            chart={
              <LineChartRecharts
                data={convertedChartData}
                xKey="month"
                yKeys={filterExistingKeys(keys)}
                colors={colors}
              />
            }
          />
        );
      case "Bar Chart":
        return (
          <ChartBox
            key={index}
            title="Bar Chart"
            chart={
              <BarChartNivo
                data={formattedData}
                indexBy="month"
                keys={filterExistingKeys(keys)}
              />
            }
          />
        );
      case "Pie Chart":
        return (
          <ChartBox
            key={index}
            title="Pie Chart"
            chart={<PieChartNivo data={pieData} />}
          />
        );
      case "Gantt Chart":
        return (
          <ChartBox
            key={index}
            title="Gantt Chart"
            chart={
              <GanttChartNivo
                data={formattedData}
                keys={filterExistingKeys(keys)}
                indexBy="month"
              />
            }
          />
        );
      case "Histogram":
        return (
          <ChartBox
            key={index}
            title="Histogram"
            chart={
              <BarChartNivo
                data={convertedChartData}
                indexBy="month"
                keys={filterExistingKeys(keys)}
              />
            }
          />
        );
      case "Column Chart":
        return (
          <ChartBox
            key={index}
            title="Column Chart"
            chart={
              <BarChartNivo
                data={convertedChartData}
                indexBy="month"
                keys={filterExistingKeys(keys)}
              />
            }
          />
        );
      case "Area Chart":
        return (
          <ChartBox
            key={index}
            title="Area Chart"
            chart={
              <AreaChartRecharts
                data={convertedChartData}
                xKey="month"
                yKeys={filterExistingKeys(keys)}
                colors={colors}
              />
            }
          />
        );
      case "Funnel Chart":
        return (
          <ChartBox
            key={index}
            title="Funnel Chart"
            chart={<FunnelChartNivo data={convertedChartData} />}
          />
        );
      case "Geographic Map":
        return (
          <ChartBox
            key={index}
            title="Geographic Map"
            chart={<GeographicMap data={convertedChartData} />}
          />
        );
      case "Heatmap":
        return (
          <ChartBox
            key={index}
            title="Heatmap"
            chart={<HeatmapNivo data={convertedChartData} />}
          />
        );
      // case "Radar Chart":
      //   return (
      //     <ChartBox
      //       key={index}
      //       title="Radar Chart"
      //       chart={<RadarChartRecharts data={convertedChartData} />}
      //     />
      //   );
      case "Sankey Diagram":
        return (
          <ChartBox
            key={index}
            title="Sankey Diagram"
            chart={<SankeyNivo data={convertedChartData} />}
          />
        );
      case "Scatter Plot":
        return (
          <ChartBox
            key={index}
            title="Scatter Plot"
            chart={<ScatterPlotRecharts data={convertedChartData} />}
          />
        );
      case "Time Series Chart":
        return (
          <ChartBox
            key={index}
            title="Time Series Chart"
            chart={
              <LineChartRecharts
                data={convertedChartData}
                xKey="month"
                yKeys={filterExistingKeys(["value"])}
                colors={colors}
              />
            }
          />
        );
      case "Waterfall Chart":
        return (
          <ChartBox
            key={index}
            title="Waterfall Chart"
            chart={<WaterfallRecharts data={convertedChartData} />}
          />
        );
      case "Stacked Bar Chart":
        return (
          <ChartBox
            key={index}
            title="Stacked Bar Chart"
            chart={
              <StackedBarChartNivo
                data={formattedData}
                keys={filterExistingKeys(keys)}
              />
            }
          />
        );
      default:
        return null;
    }
  });
};
