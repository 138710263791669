import { Navigate, Route, Routes } from "react-router-dom";
import { ROUTES } from "./constants/routes";
import { Home } from "./pages/home/Home";
import { KpiAnalyticView } from "./pages/view/KpiAnalyticView";
import { SalesForecastChart } from "./pages/dashboard/Sale";
import { Login } from "./pages/login/Login";
import { useLayoutEffect } from "react";
import { useAppDispatch, useAppSelector } from "./app/hooks";
import { useMeMutation } from "./api/auth.api";
import { DefaultLayout } from "./containers/DefaultLayout";
import { ProtectedLayout } from "./containers/ProtectedLayout";
import { setAuth } from "./slices/auth.slice";
import { LayoutProvider } from "./containers/LayoutContext";

function App() {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.auth.username);

  const [me] = useMeMutation();

  useLayoutEffect(() => {
    const fetchUser = async () => {
      if (!user) {
        try {
          const response = await me().unwrap();
          dispatch(setAuth({ username: response.username }));
        } catch (err) {
          dispatch(setAuth({ username: undefined }));
        }
      }
    };

    fetchUser();
  }, [dispatch, me, user]);

  if (user === null) return <></>;

  return (
    <LayoutProvider>
      <Routes>
        <Route element={<DefaultLayout />}>
          <Route path="/" element={<Navigate to={ROUTES.login} replace />} />
          <Route path={ROUTES.login} element={<Login />} />
        </Route>
        <Route element={<ProtectedLayout />}>
          <Route path="/" element={<Navigate to={ROUTES.home} replace />} />
          <Route path={ROUTES.home} element={<Home />} />
          <Route path={ROUTES.kpiAnalyticView} element={<KpiAnalyticView />} />
          <Route path={ROUTES.sale} element={<SalesForecastChart />} />
        </Route>
      </Routes>
    </LayoutProvider>
  );
}

export default App;
