export const colors = [
  "#8884d8", // Soft Blue
  "#82ca9d", // Light Green
  "#ffc658", // Yellow
  "#ff8042", // Orange
  "#a83279", // Purple-Pink
  "#50c878", // Emerald Green
  "#ff6347", // Tomato Red
  "#4682b4", // Steel Blue
  "#ffb6c1", // Light Pink
  "#dda0dd", // Plum
  "#20b2aa", // Light Sea Green
  "#ffd700", // Gold
  "#1e90ff", // Dodger Blue
  "#32cd32", // Lime Green
];
