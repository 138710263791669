import React, { createContext, useContext, useState } from "react";

interface LayoutContextType {
  dnaData: any | null;
  setDnaData: (name: any | null) => void;
}

const LayoutContext = createContext<LayoutContextType>({
  dnaData: null,
  setDnaData: () => {},
});

export const LayoutProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [dnaData, setDnaData] = useState<any | null>(null);

  return (
    <LayoutContext.Provider value={{ dnaData, setDnaData }}>
      {children}
    </LayoutContext.Provider>
  );
};

export const useLayout = () => useContext(LayoutContext);
