import React from "react";
import styles from "./CategoryBox.module.css";
import { KpiBox, KpiBoxProps } from "../KpiBox/KpiBox";

interface Props {
  icon: React.ReactNode;
  title: string;
  kpis: KpiBoxProps[];
  onClick?: (name?: string) => void;
}

export const CategoryBox: React.FC<Props> = ({
  icon,
  title,
  kpis,
  onClick,
}) => {
  return (
    <div className={styles.box}>
      <div className={styles.header}>
        {icon}
        <h3>{title}</h3>
      </div>
      {kpis?.map((kpi, index) => (
        <KpiBox key={index} {...kpi} onClick={() => onClick?.(kpi.title)} />
      ))}
    </div>
  );
};
