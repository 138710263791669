export const generalChartDataConverter = (data: any) => {
  if (!data?.length) return [];

  return data?.map((item: any) => {
    let convertedItem = { ...item };

    if (
      (item?.month || item?.forecast_month) &&
      (item?.year || item?.forecast_year)
    ) {
      const month = item?.forecast_month || item?.month;
      const year = item?.forecast_year || item?.year;
      const monthYear = `${month}/${year}`;
      convertedItem = {
        ...convertedItem,
        month_year: monthYear,
        month,
        year,
      };
    }

    if (item?.product_name?.en_US) {
      const productName = item.product_name.en_US;
      const { product_name, ...rest } = item;
      convertedItem = {
        product_name: productName,
        ...rest,
      };
    }

    if (item?.stage_name?.en_US) {
      const stageName = item.stage_name.en_US;
      const { stage_name, ...rest } = item;
      convertedItem = {
        stage_name: stageName,
        ...rest,
      };
    }

    return convertedItem;
  });
};

export const groupChartData = (
  chartD: any[],
  selectedIndex: string,
  selectedKeys: string[]
) => {
  if (
    !chartD ||
    !Array.isArray(chartD) ||
    !selectedIndex ||
    !selectedKeys?.length
  ) {
    return [];
  }

  const validData = chartD.filter((item) => item && typeof item === "object");

  if (!validData.length) {
    return [];
  }

  const groupedData = validData.reduce(
    (acc: Record<string, any>, item: any) => {
      const groupKey = item[selectedIndex];

      if (!groupKey) return acc;

      if (!acc[groupKey]) {
        acc[groupKey] = {
          [selectedIndex]: groupKey,
          ...item,
        };

        selectedKeys.forEach((key) => {
          acc[groupKey][key] = 0;
        });
      }

      selectedKeys.forEach((key) => {
        acc[groupKey][key] += Number(item[key]) || 0;
      });

      return acc;
    },
    {} as Record<string, any>
  );

  return Object.values(groupedData).map((item) => {
    const formattedItem = { ...item };
    selectedKeys.forEach((key) => {
      const value = formattedItem[key];
      formattedItem[key] = Number(Number(value).toFixed(2));
    });
    return formattedItem;
  });
};
