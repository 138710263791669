// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ChartBox_box__ZbqlB {
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  border: 1px solid #ebecef;
  border-radius: 12px;
  transition: all 0.3s ease;
}

.ChartBox_chart__d9Xnx {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 500px;
}

@media (max-width: 1024px) {
  .ChartBox_box__ZbqlB {
    padding: 12px;
    gap: 12px;
  }

  .ChartBox_chart__d9Xnx {
    height: 500px;
  }
}

@media (max-width: 768px) {
  .ChartBox_box__ZbqlB {
    padding: 10px;
    gap: 10px;
  }

  .ChartBox_chart__d9Xnx {
    height: 500px;
  }
}

@media (max-width: 480px) {
  .ChartBox_box__ZbqlB {
    padding: 8px;
    gap: 8px;
  }

  h4 {
    font-size: 16px;
    text-align: center;
  }

  .ChartBox_chart__d9Xnx {
    height: 500px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/boxes/ChartBox/ChartBox.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,yBAAyB;EACzB,mBAAmB;EACnB,yBAAyB;AAC3B;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;AACf;;AAEA;EACE;IACE,aAAa;IACb,SAAS;EACX;;EAEA;IACE,aAAa;EACf;AACF;;AAEA;EACE;IACE,aAAa;IACb,SAAS;EACX;;EAEA;IACE,aAAa;EACf;AACF;;AAEA;EACE;IACE,YAAY;IACZ,QAAQ;EACV;;EAEA;IACE,eAAe;IACf,kBAAkB;EACpB;;EAEA;IACE,aAAa;EACf;AACF","sourcesContent":[".box {\n  padding: 16px;\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n  border: 1px solid #ebecef;\n  border-radius: 12px;\n  transition: all 0.3s ease;\n}\n\n.chart {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 500px;\n}\n\n@media (max-width: 1024px) {\n  .box {\n    padding: 12px;\n    gap: 12px;\n  }\n\n  .chart {\n    height: 500px;\n  }\n}\n\n@media (max-width: 768px) {\n  .box {\n    padding: 10px;\n    gap: 10px;\n  }\n\n  .chart {\n    height: 500px;\n  }\n}\n\n@media (max-width: 480px) {\n  .box {\n    padding: 8px;\n    gap: 8px;\n  }\n\n  h4 {\n    font-size: 16px;\n    text-align: center;\n  }\n\n  .chart {\n    height: 500px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": `ChartBox_box__ZbqlB`,
	"chart": `ChartBox_chart__d9Xnx`
};
export default ___CSS_LOADER_EXPORT___;
