// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.List_listContainer__9bBKq {
  margin: 1.5rem 0;
}

.List_list__GiCdP {
  list-style-type: none;
  padding-left: 0;
  margin: 0;
}

.List_listItem__b6sKt {
  padding: 0.5rem 0;
  font-size: 1rem;
  line-height: 1.5;
  border-bottom: 1px solid #ddd;
}

.List_listItem__b6sKt:last-child {
  border-bottom: none;
}

.List_listItem__b6sKt:hover {
  background-color: #f9f9f9;
}
`, "",{"version":3,"sources":["webpack://./src/components/lists/List/List.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,qBAAqB;EACrB,eAAe;EACf,SAAS;AACX;;AAEA;EACE,iBAAiB;EACjB,eAAe;EACf,gBAAgB;EAChB,6BAA6B;AAC/B;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".listContainer {\n  margin: 1.5rem 0;\n}\n\n.list {\n  list-style-type: none;\n  padding-left: 0;\n  margin: 0;\n}\n\n.listItem {\n  padding: 0.5rem 0;\n  font-size: 1rem;\n  line-height: 1.5;\n  border-bottom: 1px solid #ddd;\n}\n\n.listItem:last-child {\n  border-bottom: none;\n}\n\n.listItem:hover {\n  background-color: #f9f9f9;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"listContainer": `List_listContainer__9bBKq`,
	"list": `List_list__GiCdP`,
	"listItem": `List_listItem__b6sKt`
};
export default ___CSS_LOADER_EXPORT___;
