import React from "react";
import styles from "./QABox.module.css";

export interface QABoxProps {
  icon?: React.ReactNode;
  question: string;
  answer: string;
}

export const QABox: React.FC<QABoxProps> = ({ icon, question, answer }) => {
  return (
    <div className={styles.box}>
      {icon}
      <div className={styles.qa}>
        <h6 className={styles.question}>{question}</h6>
        <p className={styles.answer}>{answer}</p>
      </div>
    </div>
  );
};
