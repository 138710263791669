// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ButtonIcon_button__zRl66 {
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 4px;
  background-color: #2563eb;
  color: white;
  border-radius: 8px;
  border: none;
  cursor: pointer;
}

.ButtonIcon_text__uQ2BW {
  font-weight: 200;
  font-size: 16;
}
`, "",{"version":3,"sources":["webpack://./src/components/buttons/ButtonIcon/ButtonIcon.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,mBAAmB;EACnB,QAAQ;EACR,yBAAyB;EACzB,YAAY;EACZ,kBAAkB;EAClB,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,gBAAgB;EAChB,aAAa;AACf","sourcesContent":[".button {\n  padding: 8px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-direction: row;\n  gap: 4px;\n  background-color: #2563eb;\n  color: white;\n  border-radius: 8px;\n  border: none;\n  cursor: pointer;\n}\n\n.text {\n  font-weight: 200;\n  font-size: 16;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `ButtonIcon_button__zRl66`,
	"text": `ButtonIcon_text__uQ2BW`
};
export default ___CSS_LOADER_EXPORT___;
