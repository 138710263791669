import React from "react";
import { Treemap, ResponsiveContainer, Tooltip } from "recharts";

interface TreeMapRechartsProps {
  data: any;
}

export const TreeMapRecharts: React.FC<TreeMapRechartsProps> = ({ data }) => {
  const treeData = data[0]?.children || [];

  const CustomTooltip = ({ active, payload }: any) => {
    if (!active || !payload || !payload.length) return null;

    const data = payload[0].payload;
    return (
      <div
        style={{
          backgroundColor: "#fff",
          padding: "10px",
          border: "1px solid #ccc",
          borderRadius: "4px",
        }}
      >
        <p style={{ margin: 0 }}>
          <strong>Name: {data.name}</strong>
        </p>
        <p style={{ margin: 0 }}>Value: {data.size?.toLocaleString()}</p>
      </div>
    );
  };

  return (
    <ResponsiveContainer width="100%" height="100%">
      <Treemap
        width={400}
        height={200}
        data={treeData}
        dataKey="size"
        aspectRatio={4 / 3}
        stroke="#fff"
      >
        <Tooltip content={CustomTooltip} />
      </Treemap>
    </ResponsiveContainer>
  );
};
