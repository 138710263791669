// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Sale.css */

.container {
  padding: 1rem;
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.title {
  font-size: 1.25rem;
  font-weight: bold;
}

.select {
  border: 1px solid #e5e7eb;
  border-radius: 0.25rem;
  padding: 0.25rem;
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.table th,
.table td {
  border: 1px solid #e5e7eb;
  padding: 0.5rem;
  text-align: right;
}

.table th {
  background-color: #f3f4f6;
}

.table tr:hover {
  background-color: #f9fafb;
}

.forecastDetails {
  margin-top: 1rem;
  font-size: 0.875rem;
  color: #4b5563;
}

.list {
  list-style-type: disc;
  padding-left: 1.25rem;
}
`, "",{"version":3,"sources":["webpack://./src/pages/dashboard/Sale.css"],"names":[],"mappings":"AAAA,aAAa;;AAEb;EACE,aAAa;EACb,uBAAuB;EACvB,qBAAqB;EACrB,wCAAwC;AAC1C;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;EACE,yBAAyB;EACzB,sBAAsB;EACtB,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,yBAAyB;AAC3B;;AAEA;;EAEE,yBAAyB;EACzB,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;EACnB,cAAc;AAChB;;AAEA;EACE,qBAAqB;EACrB,qBAAqB;AACvB","sourcesContent":["/* Sale.css */\n\n.container {\n  padding: 1rem;\n  background-color: white;\n  border-radius: 0.5rem;\n  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);\n}\n\n.header {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin-bottom: 1rem;\n}\n\n.title {\n  font-size: 1.25rem;\n  font-weight: bold;\n}\n\n.select {\n  border: 1px solid #e5e7eb;\n  border-radius: 0.25rem;\n  padding: 0.25rem;\n}\n\n.table {\n  width: 100%;\n  border-collapse: collapse;\n}\n\n.table th,\n.table td {\n  border: 1px solid #e5e7eb;\n  padding: 0.5rem;\n  text-align: right;\n}\n\n.table th {\n  background-color: #f3f4f6;\n}\n\n.table tr:hover {\n  background-color: #f9fafb;\n}\n\n.forecastDetails {\n  margin-top: 1rem;\n  font-size: 0.875rem;\n  color: #4b5563;\n}\n\n.list {\n  list-style-type: disc;\n  padding-left: 1.25rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
