import React from "react";
import styles from "./NumericChip.module.css";

interface NumericChipProps {
  children: React.ReactNode;
}

export const NumericChip: React.FC<NumericChipProps> = ({ children }) => {
  return (
    <div className={styles.box}>
      <p className={styles.text}>{children}</p>
    </div>
  );
};
