import React, { useEffect, useState } from "react";
import { ArrowLeft, Clock, Info, TriangleAlertIcon } from "lucide-react";
import { Link, useParams } from "react-router-dom";
import "./KpiAnalyticView.css";
import { DetailBox } from "../../components/boxes/DetailBox/DetailBox";
import { DataBox } from "../../components/boxes/DataBox/DataBox";
import { Loading } from "../Loading";
import {
  chips,
  dataSourceDetails,
  keyInsightsDetails,
  qas,
  recommendedActionsDetails,
} from "../../constants/general";
import { renderCharts } from "../../utils/charts/renderCharts";
import { ChartBox } from "../../components/boxes/ChartBox/ChartBox";
import { FunnelChartNivo } from "../../components/charts/FunnelChart/nivo/FunnelChartNivo";
import { GeoMapNivo } from "../../components/charts/GeographicMap/nivo/GeoMapNivo";
import { HeatmapNivo } from "../../components/charts/Heatmap/nivo/HeatmapNivo";
import { SankeyNivo } from "../../components/charts/SankeyDiagram/nivo/SankeyNivo";
import {
  funnelData,
  heatmapData,
  sankeyData,
  WaterfallData,
} from "../../constants/charts/mockData";
import { WaterfallRecharts } from "../../components/charts/WaterfallChart/recharts/WaterfallRecharts";
import { generateCumulativeData } from "../../utils/charts/generateCumulativeData";
import { useDnaMutation } from "../../api/ios.api";
import { DialogPopup } from "../../components/dialogs/DialogPopup/DialogPopup";
import { dialogContent } from "../../constants/dialogContent";

export const KpiAnalyticView: React.FC = () => {
  const { dnaParam } = useParams();
  const [dnaData, setDnaData] = useState<any>();
  const [loading, setLoading] = useState<boolean>(true);
  const [filter, setFilter] = useState<string>("All");
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedDialogContent, setSelectedDialogContent] = useState<any>(null);

  const [ios] = useDnaMutation();

  useEffect(() => {
    const fetchKpi = async () => {
      try {
        const response = await ios(dnaParam!);
        if ("data" in response) {
          setDnaData(response.data[0]);
        } else {
          console.error("Error fetching kpis:", response.error);
        }
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchKpi();
  }, [ios, dnaParam]);

  if (loading) {
    return <Loading />;
  }

  const toggleDialog = (name?: string) => {
    if (name) {
      const content = dialogContent.find((item) => item.name === name);
      setSelectedDialogContent(content || null);
    }
    setIsDialogOpen(!isDialogOpen);
  };

  const filterData = () => {
    if (!dnaData?.data) return [];

    if (filter === "Months") {
      return dnaData.data.filter((item: any) => item.month);
    }
    if (filter === "Years") {
      return dnaData.data.filter((item: any) => item.year);
    }
    return dnaData.data;
  };

  const filteredData = filterData();

  return (
    <div className="Page">
      <Link to="/dna" className="Link">
        <ArrowLeft size={18} /> Back
      </Link>
      <div className="Header">
        <div className="HeaderTitle">
          <div className="HeaderTitleIcon">
            <h1>{dnaData?.name}</h1>
            <Info
              size={32}
              onClick={() => toggleDialog(dnaData?.name)}
              style={{ cursor: "pointer" }}
            />
          </div>
          <p className="HeaderTitleDescription">
            <Clock size={14} /> Last updated: Today at 09:00 AM
          </p>
        </div>
        {/* <div className="HeaderCardBox">
          <TriangleAlertIcon color="red" />
          <div className="HeaderCardBoxInfo">
            <h4>Current Stockout Rate</h4>
            <span className="PercentageNumber" style={{ color: "red" }}>
              4.2%
            </span>
          </div>
        </div> */}
        <div className="SortBy">
          <label htmlFor="sortBy">Sort By:</label>
          <select
            id="sortBy"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
          >
            <option value="All">All</option>
            <option value="Months">Months</option>
            <option value="Years">Years</option>
          </select>
        </div>
      </div>
      <div className="Main">
        {renderCharts(dnaData?.visual, filteredData, dnaData?.type)}
        {/* <ChartBox
          title="Funnel Chart"
          chart={<FunnelChartNivo data={funnelData} />}
        />
        <ChartBox title="Geographic Map" chart={<GeoMapNivo />} />
        <ChartBox title="Heatmap" chart={<HeatmapNivo data={heatmapData} />} />
        <ChartBox
          title="Sankey Diagram"
          chart={<SankeyNivo data={sankeyData} />}
        />
        <ChartBox
          title="Waterfall"
          chart={
            <WaterfallRecharts data={generateCumulativeData(WaterfallData)} />
          }
        /> */}
        <DetailBox {...dataSourceDetails} />
        <DetailBox {...keyInsightsDetails} />
        <DetailBox {...recommendedActionsDetails} />
        <DataBox qas={qas} chips={chips} />
      </div>

      <DialogPopup
        isOpen={isDialogOpen}
        onClose={toggleDialog}
        data={selectedDialogContent}
      />
    </div>
  );
};
