import React, { useState } from "react";
import styles from "./FeaturedKpiBox.module.css";
import { Link } from "react-router-dom";
import { Info } from "lucide-react";

interface Props {
  icon: React.ReactNode;
  title: string;
  description: string;
  type: string;
  visualization?: string;
  factors?: string;
  color?: string;
  border?: string;
  link?: string;
  onClick?: () => void;
}

export const FeaturedKpiBox: React.FC<Props> = ({
  icon,
  title,
  description,
  type,
  visualization,
  factors,
  color,
  border,
  link = "/dpo",
  onClick,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div
      className={`${styles.box} ${isExpanded ? styles.expanded : ""}`}
      style={{ backgroundColor: color, border: border }}
      onClick={handleToggle}
    >
      <div className={styles.header}>
        <div className={styles.headerMain}>
          {icon}
          <h3>{title}</h3>
          <Info size={20} onClick={onClick} style={{ cursor: "pointer" }} />
        </div>
        <div className={styles.headerType}>{type}</div>
      </div>
      <p className={styles.description}>{description}</p>
      {isExpanded && (
        <>
          <p className={styles.details}>{factors}</p>
          <p className={styles.details}>{visualization}</p>
        </>
      )}
      <Link to={link} className={styles.link}>
        View More
      </Link>
    </div>
  );
};
