import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./KpiAnalyticView.css";
import { DetailBox } from "../../components/boxes/DetailBox/DetailBox";
import { DataBox } from "../../components/boxes/DataBox/DataBox";
import { Loading } from "../Loading";
import {
  dataSourceDetails,
  keyInsightsDetails,
  recommendedActionsDetails,
} from "../../constants/general";
import { renderCharts } from "../../utils/charts/renderCharts";
import { useDnaMutation } from "../../api/ios.api";
import { fetchDiaResponse } from "../../utils/api/dia.api";
import Toast from "../../components/toasts/Toast/Toast";
import { FilterDropdown } from "../../components/dropdowns/FilterDropdown/FilterDropdown";
import {
  useGenerateResponseMutation,
  useTagsMutation,
} from "../../api/ollama.api";
import { parseAIResponse } from "../../utils/parseAIResponse";
import { useLayout } from "../../containers/LayoutContext";

export const KpiAnalyticView: React.FC = () => {
  const { dnaParam } = useParams();
  const [loading, setLoading] = useState<boolean>(true);
  const [inputText, setInputText] = useState<string>("");
  const [selectedContent, setSelectedContent] = useState<any>(null);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<string>("");
  const [toastType, setToastType] = useState<"success" | "error" | "info">(
    "info"
  );
  const [selectedIndex, setSelectedIndex] = useState<string | null>(null);
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  const [selectedModel, setSelectedModel] = useState<string | null>(null);
  const [models, setModels] = useState<any>({});
  const { dnaData, setDnaData } = useLayout();
  const [data, setData] = useState<any | null>(null);

  const [dna] = useDnaMutation();
  const [generateResponse] = useGenerateResponseMutation();
  const [tags] = useTagsMutation();

  useEffect(() => {
    const fetchKpi = async () => {
      try {
        const response = await dna(dnaParam!);
        if ("data" in response) {
          setDnaData(response?.data[0]);
          setData(response?.data[0]?.data);
          if (!response.data[0].data || response.data[0].data.length === 0) {
            setToastMessage("No data available for this KPI");
            setToastType("error");
            return;
          }
        } else {
          console.error("Error fetching kpis:", response.error);
          setToastMessage(`No data available for visualization`);
          setToastType("error");
        }
      } catch (err) {
        setToastMessage("An unexpected error occurred while fetching KPI data");
        setToastType("error");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    const fetchModels = async () => {
      try {
        const response = await tags();
        if ("data" in response) {
          setModels(response.data);
        } else {
          console.error("Error fetching models:", response.error);
        }
      } catch (err) {
        console.error("Error fetching models:", err);
        setToastMessage("Failed to fetch available models");
        setToastType("error");
      }
    };

    fetchKpi();
    fetchModels();
  }, [dna, dnaParam, tags, setDnaData]);

  useEffect(() => {
    if (data?.length > 0) {
      const indexByOptions = Object.keys(data[0] || {}).flatMap((key) => {
        const keyLower = key.toLowerCase();

        if (keyLower === "forecast_year") return ["year"];
        if (keyLower === "forecast_month") return ["month"];

        if (
          (key === "product_name" &&
            typeof data[0]?.[key]?.en_US === "string") ||
          (key === "stage_name" && typeof data[0]?.[key]?.en_US === "string")
        ) {
          return [key];
        }

        if (
          typeof data[0]?.[key] === "number" &&
          (keyLower === "month" ||
            keyLower === "year" ||
            keyLower === "forecast_month" ||
            keyLower === "forecast_year")
        ) {
          return keyLower.includes("month") ? [key] : [];
        }

        if (typeof data[0]?.[key] === "number" && keyLower.includes("id")) {
          return [key];
        }

        return typeof data[0]?.[key] === "string" ? [key] : [];
      });

      const keys = Object.keys(data[0] || {}).filter((key) => {
        const keyLower = key.toLowerCase();
        if (typeof data[0]?.[key] === "number") {
          return !(
            keyLower === "month" ||
            keyLower === "year" ||
            keyLower === "forecast_month" ||
            keyLower === "forecast_year" ||
            keyLower.includes("id")
          );
        }
        return false;
      });

      if (indexByOptions.length > 0 && !selectedIndex) {
        setSelectedIndex(indexByOptions[0]);
        setSelectedKeys([keys[0]]);
      }
    }
  }, [dnaData, selectedIndex, selectedKeys, data]);

  if (loading) {
    return <Loading />;
  }

  const askButtonClick = async () => {
    if (!selectedModel) {
      setToastMessage("Please select a model");
      setToastType("error");
      return;
    }
    try {
      setButtonLoading(true);
      const promptResponse = await fetchDiaResponse(dnaData?.name, inputText);

      const responseAI: any = await generateResponse({
        prompt: promptResponse,
        model: selectedModel,
      }).unwrap();

      console.log(responseAI?.response);

      try {
        const parsedJson = JSON.parse(parseAIResponse(responseAI?.response));
        setSelectedContent(parsedJson);
      } catch (parseError) {
        console.error("JSON parsing error:", parseError);
        setButtonLoading(false);
        setToastMessage(
          "Error communicating with the AI model. Please try again."
        );
        setToastType("error");
      }
    } catch (error) {
      console.error("Detailed error:", error);
      setButtonLoading(false);
      setToastMessage(
        "Error communicating with the AI model. Please try again."
      );
      setToastType("error");
    } finally {
      setButtonLoading(false);
    }
  };

  const handleSelectIndex = (index: string) => setSelectedIndex(index);

  const handleToggleKey = (key: string) => {
    setSelectedKeys((prev) =>
      prev.includes(key) ? prev.filter((k) => k !== key) : [...prev, key]
    );
  };

  return (
    <div className="Page">
      <div className="Filter">
        <FilterDropdown
          data={data}
          selectedIndex={selectedIndex}
          selectedKeys={selectedKeys}
          onSelectIndex={handleSelectIndex}
          onToggleKey={handleToggleKey}
        />
      </div>
      <div className="Main">
        {renderCharts(dnaData?.visual, data, selectedIndex || "", selectedKeys)}
        <DetailBox {...dataSourceDetails} />
        <DetailBox {...keyInsightsDetails} />
        <DetailBox {...recommendedActionsDetails} />
        <DataBox
          onClick={askButtonClick}
          inputText={inputText}
          setInputText={setInputText}
          data={selectedContent}
          buttonDisabled={buttonLoading}
          models={models}
          selectedModel={selectedModel || ""}
          setSelectedModel={setSelectedModel}
        />
      </div>

      {toastMessage && (
        <Toast
          message={toastMessage}
          type={toastType}
          onClose={() => setToastMessage("")}
        />
      )}
    </div>
  );
};
