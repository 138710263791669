import React from "react";
import styles from "./List.module.css";

export interface ListProps {
  items: string[];
}

export const List: React.FC<ListProps> = ({ items }) => {
  return (
    <div className={styles.listContainer}>
      <ul className={styles.list}>
        {items.map((item, index) => (
          <li key={index} className={styles.listItem}>
            {item}
          </li>
        ))}
      </ul>
    </div>
  );
};
