import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { Link, Navigate, Outlet, useLocation } from "react-router-dom";
import { ROUTES } from "../constants/routes";
import { clearAuth } from "../slices/auth.slice";
import styles from "./ProtectedLayout.module.css";
import { removeToken } from "../utils/token";
import { removeUrl } from "../utils/url";
import { ArrowLeft, Info, LogOut } from "lucide-react";
import { useLayout } from "./LayoutContext";
import { toggleDialog } from "../utils/toggleDialog";
import { DialogPopup } from "../components/dialogs/DialogPopup/DialogPopup";

export const ProtectedLayout: React.FC = () => {
  const user = useAppSelector((state) => state.auth.username);
  const location = useLocation();
  const dispatch = useAppDispatch();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedDialogContent, setSelectedDialogContent] = useState<any>(null);
  const { dnaData } = useLayout();

  const handleLogout = () => {
    dispatch(clearAuth());
    setIsDropdownOpen(false);
    removeToken();
    removeUrl();
  };

  const handleToggleDialog = (name?: string) => {
    if (name) {
      const content = toggleDialog(name);
      setSelectedDialogContent(content);
    }
    setIsDialogOpen(!isDialogOpen);
  };

  if (!user) return <Navigate to={ROUTES.login} />;

  const headerStyle =
    location.pathname !== ROUTES.home
      ? { justifyContent: "space-between" }
      : { justifyContent: "flex-end" };

  return (
    <div className={styles.layout}>
      <header className={styles.header} style={headerStyle}>
        {location.pathname !== ROUTES.home && (
          <div className={styles.headerLeft}>
            <Link to={ROUTES.home} className="Link">
              <ArrowLeft size={18} />
            </Link>
            <div className={styles.kpiData}>
              <div className={styles.kpiNameWrapper}>
                <span className={styles.kpiName}>{dnaData?.name}</span>
                {dnaData?.name && (
                  <Info
                    size={18}
                    className={styles.infoIcon}
                    onClick={() => handleToggleDialog(dnaData?.name)}
                  />
                )}
              </div>
              <span className={styles.kpiCategory}>{dnaData?.category}</span>
            </div>
          </div>
        )}
        <div
          className={styles.username}
          onClick={() => setIsDropdownOpen((prev) => !prev)}
        >
          <div className={styles.userWrapper}>
            <div className={styles.avatar}>{user[0].toUpperCase()}</div>
            <span>{user}</span>
          </div>
          {isDropdownOpen && (
            <div className={styles.dropdown}>
              <div className={styles.dropdownHeader}>
                <div className={styles.avatarLarge}>
                  {user[0].toUpperCase()}
                </div>
                <div className={styles.userInfo}>
                  <span className={styles.userName}>{user}</span>
                </div>
              </div>
              <div className={styles.dropdownDivider} />
              <button className={styles.logoutButton} onClick={handleLogout}>
                <LogOut size={16} />
                <span>Logout</span>
              </button>
            </div>
          )}
        </div>
      </header>
      <main className={styles.main}>
        <Outlet />
        <DialogPopup
          isOpen={isDialogOpen}
          onClose={handleToggleDialog}
          data={selectedDialogContent}
        />
      </main>
    </div>
  );
};
