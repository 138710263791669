import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

interface LineChartRechartsProps {
  data: any;
  xKey: string;
  yKeys: string[];
  colors: string[];
}

export const LineChartRecharts: React.FC<LineChartRechartsProps> = ({
  data,
  xKey,
  yKeys,
  colors,
}) => {
  return (
    <ResponsiveContainer width="100%" height={300}>
      <LineChart
        data={data}
        margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey={xKey} />
        <YAxis />
        <Tooltip />
        {yKeys.map((yKey, index) => (
          <Line
            key={yKey}
            type="monotone"
            dataKey={yKey}
            stroke={colors[index]}
            strokeWidth={2}
            dot={false}
          />
        ))}
      </LineChart>
    </ResponsiveContainer>
  );
};
