import React, { useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import "./Sale.css";

export const SalesForecastChart = () => {
  // Complete sale data
  const salesData = [
    {
      sale_order_id: 21,
      product_name: { en_US: "Acoustic Bloc Screens" },
      product_quantity: 5.0,
      line_total_amount: 1475.0,
    },
    {
      sale_order_id: 22,
      product_name: { en_US: "Acoustic Bloc Screens" },
      product_quantity: 5.0,
      line_total_amount: 1475.0,
    },
    {
      sale_order_id: 23,
      product_name: { en_US: "Acoustic Bloc Screens" },
      product_quantity: 10.0,
      line_total_amount: 2950.0,
    },
    {
      sale_order_id: 23,
      product_name: { en_US: "Cabinet with Doors" },
      product_quantity: 10.0,
      line_total_amount: 1400.0,
    },
    {
      sale_order_id: 4,
      product_name: { en_US: "Virtual Interior Design" },
      product_quantity: 16.0,
      line_total_amount: 1200.0,
    },
    {
      sale_order_id: 4,
      product_name: { en_US: "Office Lamp" },
      product_quantity: 10.0,
      line_total_amount: 450.0,
    },
    {
      sale_order_id: 4,
      product_name: { en_US: "Large Meeting Table" },
      product_quantity: 3.0,
      line_total_amount: 450.0,
    },
    {
      sale_order_id: 6,
      product_name: { en_US: "Customizable Desk" },
      product_quantity: 1.0,
      line_total_amount: 750.0,
    },
    {
      sale_order_id: 7,
      product_name: { en_US: "Acoustic Bloc Screens" },
      product_quantity: 5.0,
      line_total_amount: 1475.0,
    },
    {
      sale_order_id: 7,
      product_name: { en_US: "Office Lamp" },
      product_quantity: 1.0,
      line_total_amount: 40.0,
    },
    {
      sale_order_id: 8,
      product_name: { en_US: "Drawer" },
      product_quantity: 2.0,
      line_total_amount: 221.0,
    },
    {
      sale_order_id: 8,
      product_name: { en_US: "Office Chair Black" },
      product_quantity: 2.0,
      line_total_amount: 241.0,
    },
    {
      sale_order_id: 20,
      product_name: { en_US: "Virtual Interior Design" },
      product_quantity: 24.0,
      line_total_amount: 1800.0,
    },
    {
      sale_order_id: 20,
      product_name: { en_US: "Virtual Home Staging" },
      product_quantity: 30.0,
      line_total_amount: 1147.5,
    },
  ];

  // Inventory data for context
  const inventoryData = [
    {
      product_name: { en_US: "Acoustic Bloc Screens" },
      quantity_on_hand: 16.0,
      reserved_quantity: 16.0,
      unit_price: 295.0,
    },
    {
      product_name: { en_US: "Customizable Desk" },
      quantity_on_hand: 240.0,
      reserved_quantity: 0.0,
      unit_price: 750.0,
    },
    {
      product_name: { en_US: "Office Lamp" },
      quantity_on_hand: 50.0,
      reserved_quantity: 0.0,
      unit_price: 40.0,
    },
  ];

  // Process sales data
  const productSales = salesData.reduce((acc: any, sale: any) => {
    const productName = sale.product_name["en_US"];
    if (!acc[productName]) {
      acc[productName] = {
        totalQuantity: 0,
        totalRevenue: 0,
      };
    }
    acc[productName].totalQuantity += sale.product_quantity;
    acc[productName].totalRevenue += sale.line_total_amount;
    return acc;
  }, {});

  type ProductSalesData = {
    totalQuantity: number;
    totalRevenue: number;
  };

  const forecastData = Object.entries(productSales).map(([product, data]) => {
    const typedData = data as ProductSalesData;

    const inventoryItem = inventoryData.find(
      (item) => item.product_name["en_US"] === product
    );

    return {
      product,
      currentSales: typedData.totalQuantity,
      projectedSales: Math.round(typedData.totalQuantity * 1.2),
      currentRevenue: typedData.totalRevenue,
      projectedRevenue: Math.round(typedData.totalRevenue * 1.2),
      inventoryOnHand: inventoryItem ? inventoryItem.quantity_on_hand : 0,
      unitPrice: inventoryItem ? inventoryItem.unit_price : 0,
    };
  });

  // State for sorting
  const [sortBy, setSortBy] = useState("currentSales");

  // Sort forecast data
  const sortedForecastData = [...(forecastData as any)].sort(
    (a, b) => b[sortBy] - a[sortBy]
  );

  // Prepare data for chart
  const chartData = sortedForecastData.map((item) => ({
    name: item.product,
    "Current Sales": item.currentSales,
    "Projected Sales": item.projectedSales,
  }));

  return (
    <div className="container">
      <div className="header">
        <h2 className="title">Two-Month Sales Forecast</h2>
        <div>
          <span className="mr-2">Sort by:</span>
          <select
            className="select"
            value={sortBy}
            onChange={(e) => setSortBy(e.target.value)}
          >
            <option value="currentSales">Current Sales</option>
            <option value="projectedSales">Projected Sales</option>
            <option value="currentRevenue">Current Revenue</option>
          </select>
        </div>
      </div>

      <ResponsiveContainer width="100%" height={400}>
        <BarChart data={chartData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="name"
            angle={-45}
            textAnchor="end"
            interval={0}
            height={100}
          />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="Current Sales" fill="#8884d8" />
          <Bar dataKey="Projected Sales" fill="#82ca9d" />
        </BarChart>
      </ResponsiveContainer>

      <div className="forecastDetails">
        <h3 className="font-bold mb-2">Detailed Forecast</h3>
        <table className="table">
          <thead>
            <tr>
              <th>Product</th>
              <th>Current Sales</th>
              <th>Projected Sales</th>
              <th>Current Revenue</th>
              <th>Projected Revenue</th>
            </tr>
          </thead>
          <tbody>
            {sortedForecastData.map((item, index) => (
              <tr key={index}>
                <td>{item.product}</td>
                <td>{item.currentSales}</td>
                <td>{item.projectedSales}</td>
                <td>${item.currentRevenue.toLocaleString()}</td>
                <td>${item.projectedRevenue.toLocaleString()}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="forecastDetails">
        <p>Forecast Methodology:</p>
        <ul className="list">
          <li>Based on historical sales data across all products</li>
          <li>20% growth projection for next two months</li>
          <li>Sortable forecast with multiple metrics</li>
        </ul>
      </div>
    </div>
  );
};
