// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CategoryBox_box__S-kRe {
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  border: 1px solid #ebecef;
  border-radius: 12px;
  background-color: white;
  transition: all 0.3s ease;
}

.CategoryBox_box__S-kRe:hover {
  transform: scale(1.02);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.CategoryBox_header__E6ujv {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
}

.CategoryBox_header__E6ujv h3 {
  font-size: 18px;
  font-weight: 600;
  color: #333;
}

@media (max-width: 768px) {
  .CategoryBox_box__S-kRe {
    padding: 12px;
    gap: 12px;
  }

  .CategoryBox_header__E6ujv {
    flex-direction: column;
    align-items: flex-start;
  }

  .CategoryBox_header__E6ujv h3 {
    font-size: 16px;
  }
}

@media (min-width: 1024px) {
  .CategoryBox_box__S-kRe {
    padding: 20px;
    gap: 20px;
  }

  .CategoryBox_header__E6ujv h3 {
    font-size: 20px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/boxes/CategoryBox/CategoryBox.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,yBAAyB;EACzB,mBAAmB;EACnB,uBAAuB;EACvB,yBAAyB;AAC3B;;AAEA;EACE,sBAAsB;EACtB,2CAA2C;AAC7C;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,QAAQ;EACR,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,WAAW;AACb;;AAEA;EACE;IACE,aAAa;IACb,SAAS;EACX;;EAEA;IACE,sBAAsB;IACtB,uBAAuB;EACzB;;EAEA;IACE,eAAe;EACjB;AACF;;AAEA;EACE;IACE,aAAa;IACb,SAAS;EACX;;EAEA;IACE,eAAe;EACjB;AACF","sourcesContent":[".box {\n  padding: 16px;\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n  border: 1px solid #ebecef;\n  border-radius: 12px;\n  background-color: white;\n  transition: all 0.3s ease;\n}\n\n.box:hover {\n  transform: scale(1.02);\n  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);\n}\n\n.header {\n  display: flex;\n  flex-direction: row;\n  gap: 8px;\n  align-items: center;\n}\n\n.header h3 {\n  font-size: 18px;\n  font-weight: 600;\n  color: #333;\n}\n\n@media (max-width: 768px) {\n  .box {\n    padding: 12px;\n    gap: 12px;\n  }\n\n  .header {\n    flex-direction: column;\n    align-items: flex-start;\n  }\n\n  .header h3 {\n    font-size: 16px;\n  }\n}\n\n@media (min-width: 1024px) {\n  .box {\n    padding: 20px;\n    gap: 20px;\n  }\n\n  .header h3 {\n    font-size: 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": `CategoryBox_box__S-kRe`,
	"header": `CategoryBox_header__E6ujv`
};
export default ___CSS_LOADER_EXPORT___;
