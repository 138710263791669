import React, { useState } from "react";
import styles from "./KpiBox.module.css";
import { Link } from "react-router-dom";
import { Info } from "lucide-react";

export interface KpiBoxProps {
  title: string;
  description: string;
  type: string;
  category: string;
  visualization?: string;
  factors?: string;
  link?: string;
  onClick?: (name: string) => void;
}

export const KpiBox: React.FC<KpiBoxProps> = ({
  title,
  description,
  type,
  category,
  visualization,
  factors,
  link = "/dna/dpo",
  onClick,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div
      className={`${styles.box} ${isExpanded ? styles.expanded : ""}`}
      onClick={handleToggle}
    >
      <div className={styles.header}>
        <div className={styles.headerTitle}>
          <h3>{title}</h3>
          <Info
            size={20}
            onClick={() => onClick?.(title)}
            style={{ cursor: "pointer" }}
          />
        </div>
        <h3>{type}</h3>
      </div>
      <p className={styles.description}>{description}</p>
      <p className={styles.footer}>{category}</p>
      {isExpanded && (
        <>
          <p className={styles.details}>{factors}</p>
          <p className={styles.details}>{visualization}</p>
        </>
      )}
      <Link to={link} className={styles.link}>
        View More
      </Link>
    </div>
  );
};
