import React from "react";
import {
  ScatterChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Scatter,
  Legend,
  ResponsiveContainer,
} from "recharts";

interface ScatterPlotRechartsProps {
  data: any;
  color?: string;
  legendName?: string;
}

export const ScatterPlotRecharts: React.FC<ScatterPlotRechartsProps> = ({
  data,
  color = "#0088FE",
  legendName = "Scatter Points",
}) => {
  return (
    <ResponsiveContainer width="100%" height={300}>
      <ScatterChart margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="x"
          type="number"
          name="X-axis"
          label={{ value: "X-axis", position: "insideBottom", offset: -10 }}
        />
        <YAxis
          dataKey="y"
          type="number"
          name="Y-axis"
          label={{ value: "Y-axis", angle: -90, position: "insideLeft" }}
        />
        <Tooltip cursor={{ strokeDasharray: "3 3" }} />
        <Scatter name={legendName} data={data} fill={color} />
        <Legend />
      </ScatterChart>
    </ResponsiveContainer>
  );
};
