import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_URI, AUTH_TOKEN } from "./constant/const";

export const iosApi = createApi({
  reducerPath: "iosApi",
  baseQuery: fetchBaseQuery({
    baseUrl: API_URI + "/ios",
    headers: {
      Authorization: `${AUTH_TOKEN}`,
    },
  }),
  endpoints: (build) => ({
    dnas: build.mutation<any, void>({
      query: () => ({
        url: "/",
        method: "GET",
      }),
    }),
    dna: build.mutation<any, string>({
      query: (dna) => ({
        url: `/${dna}`,
        method: "GET",
      }),
    }),
  }),
});

export const { useDnasMutation, useDnaMutation } = iosApi;
