export const generateColor = (index: number) => {
  const hue = (index * 40) % 360;
  const background = `hsl(${hue}, 70%, 90%)`;
  const border = `hsl(${hue}, 70%, 70%)`;
  return { background, border };
};

export const getColor = (index: number) => {
  const colors = [
    {
      color: "#E4EFFE",
      border: "1px solid #D9E8FA",
    },
    {
      color: "#E6FCED",
      border: "1px solid #C6F6D5",
    },
    {
      color: "#F7F0FF",
      border: "1px solid #E9D8FD",
    },
  ];

  return {
    color: colors[index % colors.length].color,
    border: colors[index % colors.length].border,
  };
};
