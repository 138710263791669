// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Table_table__\\+Kg4G {
  width: 100%;
  border-collapse: collapse;
  background-color: #fff;
  border: 1px solid #ddd;
}

.Table_table__\\+Kg4G th,
.Table_table__\\+Kg4G td {
  padding: 12px 16px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.Table_table__\\+Kg4G th {
  background-color: #f5f5f5;
  font-weight: bold;
}

.Table_table__\\+Kg4G tr:hover {
  background-color: #f9f9f9;
}
`, "",{"version":3,"sources":["webpack://./src/components/tables/Table/Table.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,yBAAyB;EACzB,sBAAsB;EACtB,sBAAsB;AACxB;;AAEA;;EAEE,kBAAkB;EAClB,gBAAgB;EAChB,6BAA6B;AAC/B;;AAEA;EACE,yBAAyB;EACzB,iBAAiB;AACnB;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".table {\n  width: 100%;\n  border-collapse: collapse;\n  background-color: #fff;\n  border: 1px solid #ddd;\n}\n\n.table th,\n.table td {\n  padding: 12px 16px;\n  text-align: left;\n  border-bottom: 1px solid #ddd;\n}\n\n.table th {\n  background-color: #f5f5f5;\n  font-weight: bold;\n}\n\n.table tr:hover {\n  background-color: #f9f9f9;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table": `Table_table__+Kg4G`
};
export default ___CSS_LOADER_EXPORT___;
