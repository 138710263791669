import { Navigate, Route, Routes } from "react-router-dom";
import { ROUTES } from "./constants/routes";
import { Home } from "./pages/home/Home";
import { KpiAnalyticView } from "./pages/view/KpiAnalyticView";
import { SalesForecastChart } from "./pages/dashboard/Sale";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Navigate to={ROUTES.home} replace />} />
      <Route path={ROUTES.home} element={<Home />} />
      <Route path={ROUTES.kpiAnalyticView} element={<KpiAnalyticView />} />
      <Route path={ROUTES.sale} element={<SalesForecastChart />} />
    </Routes>
  );
}

export default App;
