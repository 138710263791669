// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Chip_box__q\\+uzF {
  display: inline-flex;
  align-items: center;
  padding: 6px 12px;
  border-radius: 24px;
  background-color: #f3f4f6;
  transition: all 0.3s ease;
}

.Chip_text__E2G-0 {
  font-weight: 400;
  color: gray;
  font-size: 14px;
  text-align: center;
  white-space: nowrap;
}

@media (max-width: 768px) {
  .Chip_box__q\\+uzF {
    padding: 4px 10px;
  }

  .Chip_text__E2G-0 {
    font-size: 13px;
  }
}

@media (max-width: 480px) {
  .Chip_box__q\\+uzF {
    padding: 4px 8px;
  }

  .Chip_text__E2G-0 {
    font-size: 12px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/chips/Chip/Chip.module.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,mBAAmB;EACnB,iBAAiB;EACjB,mBAAmB;EACnB,yBAAyB;EACzB,yBAAyB;AAC3B;;AAEA;EACE,gBAAgB;EAChB,WAAW;EACX,eAAe;EACf,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE;IACE,iBAAiB;EACnB;;EAEA;IACE,eAAe;EACjB;AACF;;AAEA;EACE;IACE,gBAAgB;EAClB;;EAEA;IACE,eAAe;EACjB;AACF","sourcesContent":[".box {\n  display: inline-flex;\n  align-items: center;\n  padding: 6px 12px;\n  border-radius: 24px;\n  background-color: #f3f4f6;\n  transition: all 0.3s ease;\n}\n\n.text {\n  font-weight: 400;\n  color: gray;\n  font-size: 14px;\n  text-align: center;\n  white-space: nowrap;\n}\n\n@media (max-width: 768px) {\n  .box {\n    padding: 4px 10px;\n  }\n\n  .text {\n    font-size: 13px;\n  }\n}\n\n@media (max-width: 480px) {\n  .box {\n    padding: 4px 8px;\n  }\n\n  .text {\n    font-size: 12px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": `Chip_box__q+uzF`,
	"text": `Chip_text__E2G-0`
};
export default ___CSS_LOADER_EXPORT___;
