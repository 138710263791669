import React, { useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import * as topojson from "topojson-client";

interface GeographicMapProps {
  data: any;
}

export const GeographicMap: React.FC<GeographicMapProps> = ({ data }) => {
  const svgRef = useRef<SVGSVGElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [dimensions, setDimensions] = useState<{
    width: number;
    height: number;
  }>({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      if (!entries.length) return;
      const { width, height } = entries[0].contentRect;
      setDimensions({ width, height });
    });

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        resizeObserver.unobserve(containerRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (
      !svgRef.current ||
      dimensions.width === 0 ||
      dimensions.height === 0 ||
      !data
    )
      return;

    const svg = d3.select(svgRef.current);
    svg.selectAll("*").remove();

    const geoData = topojson.feature(data, data.objects.countries) as unknown;

    const geoDataWithFeatures = geoData as { features: GeoJSON.Feature[] };

    const projection = d3
      .geoMercator()
      .fitSize([dimensions.width, dimensions.height], data);
    const path = d3.geoPath().projection(projection);

    const g = svg.append("g");

    g.selectAll("path")
      .data(geoDataWithFeatures.features)
      .enter()
      .append("path")
      .attr("d", (d) => path(d))
      .attr("fill", "steelblue")
      .attr("stroke", "white");
  }, [data, dimensions]);

  return (
    <div ref={containerRef} style={{ width: "100%", height: "500px" }}>
      <svg
        ref={svgRef}
        width="100%"
        height="100%"
        viewBox={`0 0 ${dimensions.width} ${dimensions.height}`}
        preserveAspectRatio="xMinYMin meet"
      ></svg>
    </div>
  );
};
