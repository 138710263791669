export const chartDataConverter = (chartD: any) => {
  const chartData = chartD.map((item: any) => {
    const monthKey = `${item?.month}/${item?.year}`;
    const convertedItem: any = {
      // item names
      month: monthKey,
      name: monthKey,
      // gantt chart details
      startDate: new Date(item?.year, item?.month - 1, 1).toLocaleDateString(),
      endDate: new Date(item?.year, item?.month - 1, 15).toLocaleDateString(),
      // id, label, data, nodes, and links will always be present
      id: item?.id,
      label: item?.label,
      data: item?.data,
      nodes: item?.nodes,
      links: item?.links,
    };

    if (item?.sale_total !== undefined) {
      convertedItem.sale_total = item?.sale_total;
    }
    if (item?.avg_revenue_per_deal !== undefined) {
      convertedItem.avg_revenue_per_deal = item?.avg_revenue_per_deal;
    }
    if (item?.avg_days_to_close !== undefined) {
      convertedItem.avg_days_to_close = item?.avg_days_to_close;
    }
    if (item?.total_quantity_sold !== undefined) {
      convertedItem.total_quantity_sold = item?.total_quantity_sold;
    }
    if (item?.total_sales_amount !== undefined) {
      convertedItem.total_sales_amount = item?.total_sales_amount;
    }
    if (item?.value !== undefined) {
      convertedItem.value = item?.value;
    }
    if (item?.value1 !== undefined) {
      convertedItem.value1 = item?.value1;
    }
    if (item?.value2 !== undefined) {
      convertedItem.value2 = item?.value2;
    }
    if (item?.value3 !== undefined) {
      convertedItem.value3 = item?.value3;
    }

    return convertedItem;
  });

  return chartData;
};

export const chartDataConverterV2 = (chartD: any) => {
  const groupedData: any = {};

  chartD.forEach((item: any) => {
    const monthYear = `${item?.month}/${item?.year}`;
    const productName = item?.product_name?.en_US;
    const totalSalesAmount = item?.total_sales_amount;

    if (!groupedData[monthYear]) {
      groupedData[monthYear] = {
        month: monthYear,
        ...Object.fromEntries(
          chartD.map((product: any) => [product?.product_name?.en_US, 0])
        ),
      };
    }

    groupedData[monthYear][productName] += totalSalesAmount;
  });

  const chartData = Object.keys(groupedData).map((key) => {
    return {
      month: key,
      ...groupedData[key],
    };
  });

  return chartData;
};
