import React from "react";
import styles from "./DataBox.module.css";
import { MessageSquare, Send } from "lucide-react";
import { QABox, QABoxProps } from "../QABox/QABox";
import { SearchBarBetween } from "../../searchbars/SearchBarBetween/SearchBarBetween";
import { ButtonIcon } from "../../buttons/ButtonIcon/ButtonIcon";
import { Chip } from "../../chips/Chip/Chip";

interface DataBoxProps {
  qas?: QABoxProps[];
  chips?: string[];
}

export const DataBox: React.FC<DataBoxProps> = ({ qas, chips }) => {
  return (
    <div className={styles.box}>
      <div className={styles.header}>
        <MessageSquare size={20} />
        <h3>Data Intelligence Assistant</h3>
      </div>
      <div className={styles.searchBarSpace}>
        <SearchBarBetween placeholder="Ask about stockout patterns, trends, or request business advice..." />
        <ButtonIcon icon={<Send size={16} />}>Ask</ButtonIcon>
      </div>
      {qas?.map((qa, index) => (
        <QABox key={index} {...qa} />
      ))}
      <div className={styles.chips}>
        {chips?.map((chip, index) => (
          <Chip key={index}>{chip}</Chip>
        ))}
      </div>
    </div>
  );
};
