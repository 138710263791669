// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Page {
  margin: 20px;
}

.Header-Home {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}

.Kpis {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 36px;
}

.FeaturedKPIs {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.FeaturedKPIsTitle {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
}
`, "",{"version":3,"sources":["webpack://./src/pages/home/Home.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,WAAW;AACb;;AAEA;EACE,gBAAgB;EAChB,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,QAAQ;EACR,mBAAmB;AACrB","sourcesContent":[".Page {\n  margin: 20px;\n}\n\n.Header-Home {\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n  width: 100%;\n}\n\n.Kpis {\n  margin-top: 24px;\n  display: flex;\n  flex-direction: column;\n  gap: 36px;\n}\n\n.FeaturedKPIs {\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n}\n\n.FeaturedKPIsTitle {\n  display: flex;\n  flex-direction: row;\n  gap: 8px;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
