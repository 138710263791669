import React, { ChangeEvent, useState } from "react";
import styles from "./SearchBar.module.css";
import { Search } from "lucide-react";
import { Link } from "react-router-dom";

interface SearchBarProps {
  placeholder: string;
  onSearch?: (value: string) => void;
  results?: {
    id: number;
    name: string;
    type: string;
    category: string;
    description: string;
    visualization: string;
  }[];
}

export const SearchBar: React.FC<SearchBarProps> = ({
  placeholder,
  onSearch,
  results = [],
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    onSearch?.(e.target.value);
  };

  const handleFocus = () => setIsOpen(true);
  const handleClose = (e: React.FocusEvent) => {
    if (!e.currentTarget.contains(e.relatedTarget as Node)) {
      setIsOpen(false);
    }
  };

  return (
    <div
      className={styles.searchBarContainer}
      onBlur={handleClose}
      tabIndex={-1}
    >
      <div className={styles.searchBar}>
        <Search size={16} />
        <input
          type="text"
          placeholder={placeholder}
          onChange={handleChange}
          onFocus={handleFocus}
        />
      </div>
      {isOpen && results.length > 0 && (
        <ul className={styles.results}>
          {results.map((result) => (
            <Link
              key={result.id}
              to={`/dna/${result.name}`}
              className={styles.resultLink}
            >
              <li className={styles.resultItem}>
                <div className={styles.resultName}>{result.name}</div>
                <div className={styles.resultMeta}>
                  <span className={styles.resultType}>{result.type}</span>
                  <span className={styles.resultCategory}>
                    {result.category}
                  </span>
                  <span className={styles.resultVisualization}>
                    {result.visualization}
                  </span>
                </div>
                <div className={styles.resultDescription}>
                  {result.description}
                </div>
              </li>
            </Link>
          ))}
        </ul>
      )}
    </div>
  );
};
