export const dialogContent = [
  {
    name: "Days Sales Outstanding (DSO)",
    sections: {
      summary:
        "The Days Sales Outstanding (DSO) report measures the average number of days it takes for a company to receive payment after making a sale. It is a key indicator of how efficiently a business manages its receivables and cash flow.",
      key_information: {
        title: "Key Information about Days Sales Outstanding (DSO)",
        table: {
          thead: {
            "1": "Information",
            "2": "Description",
          },
          tbody: [
            {
              "1": "Name: Days Sales Outstanding (DSO)",
              "2": "The average number of days it takes for a company to receive payment after making a sale.",
            },
            {
              "1": "Recommended Predictives",
              "2": "Predictive analytics that forecast the collection timeline based on historical data. These include:",
            },
            {
              "1": "Average Collection Time",
              "2": "The average number of days it takes to collect payment from customers.",
            },
            {
              "1": "Payment Trend Analysis",
              "2": "Trends in payment behavior over time, including increases or decreases in collection times.",
            },
            {
              "1": "At-Risk Accounts",
              "2": "Accounts that are taking longer than usual to pay and may require intervention.",
            },
          ],
        },
      },
      external_influencing_factors: {
        title: "External Influencing Factors",
        description:
          "The performance of DSO is influenced by various external factors:",
        list: [
          "Customer Payment Behaviors: Changes in customer payment habits, such as delays or payments in full.",
          "Economic Conditions: Economic downturns or upturns can affect the ability of customers to pay promptly.",
          "Credit Policies and Practices: Stricter credit policies may lead to longer DSO, while relaxed policies might result in quicker payments but higher risk.",
          "Market Competition: Intense competition can pressure companies to offer more favorable payment terms to retain customers.",
          "Seasonal Factors: Sales and collections may vary seasonally, impacting DSO.",
        ],
      },
    },
  },
  {
    name: "Receivables Turnover Ratio",
    sections: {
      summary:
        "The Receivables Turnover Ratio is a financial metric that measures how efficiently a company collects its receivables, or how quickly it converts credit sales into cash. This report will focus on the effectiveness of using receivables for generating sales and forecasting potential cash flow issues.",
      key_information: {
        title: "Key Information about Receivables Turnover Ratio",
        table: {
          thead: {
            "1": "Name of Metric",
            "2": "Description and Importance",
          },
          tbody: [
            {
              "1": "Receivables Turnover Ratio",
              "2": "It indicates the number of times a company's receivables are collected over a period. A higher ratio is generally better as it signifies efficient collection and faster cash flow.",
            },
          ],
        },
      },
      recommended_predictives: {
        title: "Recommended Predictives",
        list: [
          "Potential delays in payment by customers",
          "Inability to meet financial obligations due to slow collection times",
          "Impact on working capital and liquidity",
        ],
      },
      external_influencing_factors: {
        title: "External Influencing Factors",
        table: {
          thead: {
            "1": "Factor",
            "2": "Explanation",
          },
          tbody: [
            {
              "1": "Economic Conditions",
              "2": "During economic downturns, customers may face financial difficulties leading to slower payments. Conversely, during growth periods, faster turnover can be expected.",
            },
            {
              "1": "Sales Strategies",
              "2": "The effectiveness of credit policies and collection practices directly impacts the receivables turnover ratio. Aggressive but fair collection strategies are crucial.",
            },
          ],
        },
      },
    },
  },
  {
    name: "Stockout Rate",
    sections: {
      summary:
        "The Stockout Rate report is a critical tool for managing inventory levels effectively. It measures the frequency at which stockouts occur, indicating periods when the demand exceeds supply. A high stockout rate can lead to lost sales and customer dissatisfaction.",
      key_information: {
        title: "Key Information about Stockout Rate",
        table: {
          thead: {
            "1": "Name",
            "2": "Description",
          },
          tbody: [
            {
              "1": "Stockout Rate",
              "2": "This report tracks the frequency of inventory being depleted to zero, highlighting periods where supply cannot meet demand.",
            },
          ],
        },
      },
      recommended_predictives: {
        title: "Recommended Predictives",
        list: ["Future Stock Levels Based on Historical Stockout Data"],
      },
      external_influencing_factors: {
        title: "External Influencing Factors",
        table: {
          thead: {
            "1": "Influencing Factor",
            "2": "Explanation",
          },
          tbody: [
            {
              "1": "Supplier Delays",
              "2": "These can significantly impact stockout rates. If suppliers are not able to deliver goods on time, it disrupts the supply chain and can lead to unexpected shortages.",
            },
            {
              "1": "Changes in Consumer Demand",
              "2": "Fluctuations in consumer preferences or economic conditions can cause sudden increases or decreases in demand. This unpredictability makes accurate inventory management challenging but crucial for maintaining customer satisfaction and minimizing losses.",
            },
          ],
        },
      },
    },
  },
  {
    name: "Purchase Order Cycle Time",
    sections: {
      summary:
        "This report is designed to track the average time it takes from the creation of a purchase order to its fulfillment. It provides insights into supply chain efficiency and can help in identifying bottlenecks or delays.",
      key_information: {
        title: "Key Information about Purchase Order Cycle Time",
        description:
          "This metric measures the time from when a purchase order is initiated to its completion. It includes all steps involved in fulfilling an order, from receiving requisitions to payment and delivery.",
      },
      recommended_predictives: {
        title: "Recommended Predictives",
        list: [
          "The average time taken for each purchase order cycle.",
          "Identifying outliers can highlight issues or best practices.",
          "Over time, how the cycle times have changed and if there are any improvements or regressions.",
          "Predictive Analytics: Using historical data to forecast future cycle times.",
        ],
      },
      external_influencing_factors: {
        title: "External Influencing Factors",
        description:
          "The following external factors can significantly impact the Purchase Order Cycle Time:",
        table: {
          thead: {
            "1": "Factor",
            "2": "Description",
            "3": "Affect on Cycle Time",
          },
          tbody: [
            {
              "1": "Supplier Reliability",
              "2": "The consistency and dependability of suppliers in meeting deadlines.",
              "3": "Inconsistent or unreliable suppliers can increase cycle times due to delays, rejections, or quality issues.",
            },
            {
              "1": "Lead Times",
              "2": "The time required for a supplier to produce and deliver goods after receiving the purchase order.",
              "3": "Longer lead times directly increase cycle times. Shorter lead times can reduce overall cycle time but may affect stock levels and inventory management.",
            },
            {
              "1": "In-Transit Delays",
              "2": "The time taken for goods to travel from the supplier to the buyer, including transportation issues or customs delays.",
              "3": "Delays in transit can significantly impact cycle times and affect inventory levels. Efficient logistics and reliable suppliers can mitigate these risks.",
            },
            {
              "1": "Promotions and Discounts",
              "2": "The effect of promotional activities, bulk purchase discounts, or seasonal demand fluctuations on supplier behavior and order fulfillment.",
              "3": "Increased orders during promotions may strain supplier resources, leading to longer cycle times. Conversely, bulk purchases can sometimes reduce per-unit costs but may delay delivery.",
            },
            {
              "1": "Inflation and Economic Conditions",
              "2": "The impact of economic factors such as inflation or changes in currency exchange rates on purchasing power and supplier pricing.",
              "3": "Higher costs due to inflation can increase the price of goods, potentially leading suppliers to adjust their lead times or shipping practices. Fluctuating exchange rates may also affect import costs and delivery timelines.",
            },
          ],
        },
      },
    },
  },
  {
    name: "Supplier Lead Time",
    sections: {
      summary:
        "A Supplier Lead Time report is a document that outlines the average time taken by suppliers to deliver goods. It's crucial for evaluating performance, improving supply chain efficiency, and making informed decisions about strategic sourcing.",
      key_information: {
        title: "Key Information about Supplier Lead Time",
        description:
          "This metric measures the time taken by suppliers to deliver goods after receiving the purchase order. It helps assess supplier performance and manage delivery expectations.",
      },
      recommended_predictives: {
        title: "Recommended Predictives",
        list: [
          "Total lead time (average delivery time)",
          "Variability in delivery times",
          "Average on-time delivery rate",
          "Frequency of late deliveries",
          "Trend analysis over time",
          "Impact of supplier changes or new suppliers",
        ],
      },
      external_influencing_factors: {
        title: "External Influencing Factors",
        description:
          "The following external factors can significantly impact Supplier Lead Time:",
        table: {
          thead: {
            "1": "Factor",
            "2": "Description",
          },
          tbody: [
            {
              "1": "Supplier efficiency",
              "2": "The ability of suppliers to manage their internal processes and meet delivery deadlines.",
            },
            {
              "1": "Market conditions",
              "2": "Economic factors, demand levels, competition, and availability of materials can impact lead times.",
            },
            {
              "1": "Seasonal changes",
              "2": "Increased demand during certain periods can affect lead times.",
            },
            {
              "1": "Transportation disruptions",
              "2": "Issues with logistics and supply chain can delay deliveries.",
            },
            {
              "1": "Pandemic or global events",
              "2": "External factors such as pandemics, geopolitical instability, and natural disasters can disrupt supply chains.",
            },
          ],
        },
      },
    },
  },
  {
    name: "Sales Growth Rate",
    sections: {
      summary:
        "Measures the percentage increase in sales over a specific period.",
      key_information: {
        title: "Key Information about Sales Growth Rate",
        description:
          "This metric tracks the percentage change in sales over a given period, helping businesses understand the growth or decline in their sales performance.",
      },
      recommended_predictives: {
        title: "Key Predictives for Forecasting Future Sales",
        table: {
          thead: {
            "1": "Predictive",
            "2": "Description",
          },
          tbody: [
            {
              "1": "Historical Growth Rates",
              "2": "Average and standard deviation of past growth rates.",
            },
            {
              "1": "Trend Analysis",
              "2": "Seasonal trends, long-term growth patterns, and cyclical behavior analysis.",
            },
            {
              "1": "Market Demand Projections",
              "2": "Expected increase or decrease in market demand based on current and projected economic conditions.",
            },
          ],
        },
      },
      external_influencing_factors: {
        title: "External Influencing Factors",
        description:
          "The external factors listed below can significantly impact the Sales Growth Rate. Understanding these influences is crucial for accurate forecasting:",
        table: {
          thead: {
            "1": "Factor",
            "2": "Description",
          },
          tbody: [
            {
              "1": "Market Conditions",
              "2": "The overall state of the market, including customer preferences and competition.",
            },
            {
              "1": "Economic Environment",
              "2": "The broader economic conditions such as inflation rates, interest rates, and unemployment levels that affect purchasing power and business operations.",
            },
            {
              "1": "Seasonal Variations",
              "2": "Changes in sales due to seasonal fluctuations in consumer behavior or industry-specific factors.",
            },
          ],
        },
      },
    },
  },
  {
    name: "Average Deal Size",
    sections: {
      summary:
        "The Average Deal Size report provides a summary of the revenue generated per closed deal over a specific period.",
      key_information: {
        title: "Key Information about Average Deal Size",
        description:
          "The Average Deal Size report provides a summary of the revenue generated per closed deal over a specific period.",
      },
      recommended_predictives: {
        title: "Recommended Predictives for Forecasting Future Revenue",
        description:
          "Predictives include historical trends and patterns in deal sizes. These can be used to forecast future revenue by analyzing past data and identifying consistent growth or decline rates.",
      },
      external_influencing_factors: {
        title: "External Influencing Factors",
        description:
          "The external factors listed below can significantly impact the Average Deal Size. Understanding these influences is crucial for accurate forecasting:",
        list: [
          "Market Demand: High demand generally leads to larger deals as customers are more willing to invest. Conversely, low market demand can result in smaller deal sizes due to reduced interest or budget constraints.",
          "Competitive Pricing Strategies: The pricing strategies of competitors significantly impact deal size. Aggressive competition often leads to lower prices and potentially smaller deals, while strategic pricing can drive larger ones.",
        ],
      },
    },
  },
  {
    name: "Sales Cycle Length",
    sections: {
      summary:
        "This report measures the average time taken to close a sale, providing insights into sales efficiency and effectiveness.",
      key_information: {
        title: "Key Information about Sales Cycle Length",
        description:
          "This report measures the average time taken to close a sale, providing insights into sales efficiency and effectiveness.",
      },
      recommended_predictives: {
        title: "Recommended Predictives",
        description:
          "The following metrics are recommended to forecast sales cycle performance and improve efficiency:",
        list: [
          "Average Sales Cycle Time: This metric provides an overview of how long it takes on average to convert a lead into a sale.",
          "Percentage of Sales by Stage Duration: Breakdown the time spent at each stage in the sales process, highlighting bottlenecks or delays.",
          "Conversion Rate by Stage: Measures the success rate of moving through different stages of the sales cycle to identify areas for improvement.",
        ],
      },
      external_influencing_factors: {
        title: "External Influencing Factors",
        description:
          "The external factors listed below can significantly impact the Sales Cycle Length. Understanding these influences is crucial for accurate forecasting:",
        list: [
          "Sales Process Efficiency: Streamlining processes can significantly reduce cycle times. Improvements include better training, more efficient CRM tools, and optimized workflows.",
          "Customer Decision-Making Speed: Factors like market conditions, customer expectations, and the complexity of the product or service can affect how quickly customers make decisions.",
          "External Market Conditions: Economic factors such as economic growth, industry trends, and competitor actions can impact sales cycle length. For example, a recession might slow down purchasing decisions.",
        ],
      },
    },
  },
  {
    name: "Product Performance Analysis",
    sections: {
      summary:
        "This report provides a comprehensive analysis of the sales performance for each product, identifying bestsellers and underperformers. It also forecasts future production needs based on current trends and market demands.",
      key_information: {
        title: "Key Information about Product Performance Analysis",
        table: {
          thead: {
            "1": "Product Name",
            "2": "Sales Volume (Q1)",
            "3": "Sales Volume (Q2)",
            "4": "Total Sales (YTD)",
            "5": "Status",
          },
          tbody: [
            {
              "1": "Product A",
              "2": 500,
              "3": 600,
              "4": 1100,
              "5": "Bestseller",
            },
            {
              "1": "Product B",
              "2": 300,
              "3": 250,
              "4": 550,
              "5": "Underperformer",
            },
          ],
        },
      },
      recommended_predictives: {
        title: "Recommended Predictives",
        list: [
          "Predictive Analysis: Sales Trends",
          "Predictive Analysis: Seasonal Demand Patterns",
          "Predictive Analysis: Customer Purchase Behavior",
        ],
      },
      external_influencing_factors: {
        title: "External Influencing Factors",
        list: [
          "Market Demand Analysis",
          "Seasonal Influences on Sales",
          "Economic Indicators Impacting Consumer Spending",
          "Trends in Competitor Product Offerings",
          "Social Media Sentiment Analysis",
        ],
      },
      forecasting: {
        title: "Forecasting Future Production Needs Based on Product Trends",
        description:
          "Based on the analysis of sales trends and the identified bestsellers, we can forecast future production needs. This ensures that resources are allocated effectively to meet anticipated demand, reducing stockouts and overstock situations.",
      },
      why_important: {
        title: "Why It Is Important",
        description: "Accurate forecasting is crucial for several reasons:",
        list: [
          "Efficiency in Inventory Management: Ensures the right amount of stock is available to meet customer demand.",
          "Cost Savings: Reduces the costs associated with overproduction and underproduction.",
          "Satisfied Customers: Prevents out-of-stock situations that can lead to lost sales and dissatisfied customers.",
        ],
      },
      external_influences: {
        title: "External Influencing Factors",
        description:
          "The following external factors play a significant role in the accuracy of our forecasting:",
        list: [
          "Market Demand Analysis: Understanding customer preferences and market trends.",
          "Seasonal Influences on Sales: Adjusting production plans to account for seasonal fluctuations in demand.",
          "Economic Indicators Impacting Consumer Spending: Analyzing factors like inflation, unemployment rates, and disposable income levels.",
          "Trends in Competitor Product Offerings: Monitoring competitor strategies to stay competitive.",
          "Social Media Sentiment Analysis: Tracking online sentiment to gauge consumer interest and behavior.",
        ],
      },
    },
  },
];
