// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DialogPopup_overlay__JAt4n {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.DialogPopup_popup__U4RTs {
  position: relative;
  background-color: white;
  border-radius: 8px;
  width: 80%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  overflow-y: auto;
  max-height: 80vh;
}

.DialogPopup_close__nlEJ- {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.DialogPopup_title__zvR-n {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
}

.DialogPopup_section__9GCdi {
  margin-bottom: 20px;
}

.DialogPopup_sectionTitle__BvQPf {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

th,
td {
  padding: 10px;
  border-bottom: 1px solid #dee2e6;
  text-align: left;
}

th {
  background-color: #007bff;
}
`, "",{"version":3,"sources":["webpack://./src/components/dialogs/DialogPopup/DialogPopup.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,QAAQ;EACR,SAAS;EACT,oCAAoC;EACpC,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;AACf;;AAEA;EACE,kBAAkB;EAClB,uBAAuB;EACvB,kBAAkB;EAClB,UAAU;EACV,aAAa;EACb,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,wCAAwC;EACxC,+BAA+B;EAC/B,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,WAAW;EACX,eAAe;AACjB;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,yBAAyB;EACzB,gBAAgB;AAClB;;AAEA;;EAEE,aAAa;EACb,gCAAgC;EAChC,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".overlay {\n  position: fixed;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  background-color: rgba(0, 0, 0, 0.5);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  z-index: 1000;\n}\n\n.popup {\n  position: relative;\n  background-color: white;\n  border-radius: 8px;\n  width: 80%;\n  padding: 20px;\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);\n  transition: transform 0.3s ease;\n  overflow-y: auto;\n  max-height: 80vh;\n}\n\n.close {\n  position: absolute;\n  top: 10px;\n  right: 10px;\n  cursor: pointer;\n}\n\n.title {\n  font-size: 24px;\n  font-weight: bold;\n  text-align: center;\n  margin-bottom: 20px;\n}\n\n.section {\n  margin-bottom: 20px;\n}\n\n.sectionTitle {\n  font-size: 18px;\n  font-weight: bold;\n  margin-bottom: 10px;\n}\n\ntable {\n  width: 100%;\n  border-collapse: collapse;\n  margin-top: 20px;\n}\n\nth,\ntd {\n  padding: 10px;\n  border-bottom: 1px solid #dee2e6;\n  text-align: left;\n}\n\nth {\n  background-color: #007bff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"overlay": `DialogPopup_overlay__JAt4n`,
	"popup": `DialogPopup_popup__U4RTs`,
	"close": `DialogPopup_close__nlEJ-`,
	"title": `DialogPopup_title__zvR-n`,
	"section": `DialogPopup_section__9GCdi`,
	"sectionTitle": `DialogPopup_sectionTitle__BvQPf`
};
export default ___CSS_LOADER_EXPORT___;
