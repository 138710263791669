// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SearchBarBetween_searchBar__uZtMP {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  width: 100%;
  padding: 8px;
  border: 1px solid #ebecef;;
  border-radius: 8px;
}

.SearchBarBetween_searchBar__uZtMP input {
  flex: 1 1;
  border: none;
  outline: none;
}
`, "",{"version":3,"sources":["webpack://./src/components/searchbars/SearchBarBetween/SearchBarBetween.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,QAAQ;EACR,WAAW;EACX,YAAY;EACZ,yBAAyB;EACzB,kBAAkB;AACpB;;AAEA;EACE,SAAO;EACP,YAAY;EACZ,aAAa;AACf","sourcesContent":[".searchBar {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  gap: 8px;\n  width: 100%;\n  padding: 8px;\n  border: 1px solid #ebecef;;\n  border-radius: 8px;\n}\n\n.searchBar input {\n  flex: 1;\n  border: none;\n  outline: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"searchBar": `SearchBarBetween_searchBar__uZtMP`
};
export default ___CSS_LOADER_EXPORT___;
