// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.QABox_box__-vKq3 {
  padding: 16px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  border: 1px solid #ebecef;
  border-radius: 8px;
  background-color: #fff;
  align-items: flex-start;
  width: 100%;
}

.QABox_qa__ksmyz {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.QABox_question__lrnpV {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.4;
}

.QABox_answer__y7FRd {
  font-size: 14px;
  color: gray;
  line-height: 1.6;
}

@media (max-width: 768px) {
  .QABox_box__-vKq3 {
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
  }

  .QABox_question__lrnpV {
    font-size: 14px;
  }

  .QABox_answer__y7FRd {
    font-size: 13px;
  }
}

@media (min-width: 1024px) {
  .QABox_box__-vKq3 {
    padding: 20px;
    gap: 16px;
  }

  .QABox_question__lrnpV {
    font-size: 18px;
  }

  .QABox_answer__y7FRd {
    font-size: 16px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/boxes/QABox/QABox.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,aAAa;EACb,mBAAmB;EACnB,QAAQ;EACR,yBAAyB;EACzB,kBAAkB;EAClB,sBAAsB;EACtB,uBAAuB;EACvB,WAAW;AACb;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,QAAQ;AACV;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE;IACE,sBAAsB;IACtB,uBAAuB;IACvB,SAAS;EACX;;EAEA;IACE,eAAe;EACjB;;EAEA;IACE,eAAe;EACjB;AACF;;AAEA;EACE;IACE,aAAa;IACb,SAAS;EACX;;EAEA;IACE,eAAe;EACjB;;EAEA;IACE,eAAe;EACjB;AACF","sourcesContent":[".box {\n  padding: 16px;\n  display: flex;\n  flex-direction: row;\n  gap: 8px;\n  border: 1px solid #ebecef;\n  border-radius: 8px;\n  background-color: #fff;\n  align-items: flex-start;\n  width: 100%;\n}\n\n.qa {\n  display: flex;\n  flex-direction: column;\n  gap: 4px;\n}\n\n.question {\n  font-size: 16px;\n  font-weight: 600;\n  line-height: 1.4;\n}\n\n.answer {\n  font-size: 14px;\n  color: gray;\n  line-height: 1.6;\n}\n\n@media (max-width: 768px) {\n  .box {\n    flex-direction: column;\n    align-items: flex-start;\n    gap: 12px;\n  }\n\n  .question {\n    font-size: 14px;\n  }\n\n  .answer {\n    font-size: 13px;\n  }\n}\n\n@media (min-width: 1024px) {\n  .box {\n    padding: 20px;\n    gap: 16px;\n  }\n\n  .question {\n    font-size: 18px;\n  }\n\n  .answer {\n    font-size: 16px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": `QABox_box__-vKq3`,
	"qa": `QABox_qa__ksmyz`,
	"question": `QABox_question__lrnpV`,
	"answer": `QABox_answer__y7FRd`
};
export default ___CSS_LOADER_EXPORT___;
