export const generateCumulativeData = (
  data: any[],
  selectedIndex: string,
  selectedKey: string
) => {
  if (!data?.length) return [];

  const key = selectedKey;

  return data.map((item, index) => {
    const value = Number(item[key]) || 0;

    return {
      name:
        index === data.length - 1
          ? "Total"
          : item[selectedIndex] || `Item ${index + 1}`,
      [key]: value,
    };
  });
};
