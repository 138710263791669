// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ModelDropdown_dropdown__FKziU {
  position: relative;
  min-width: 200px;
}

.ModelDropdown_trigger__jQ-Rn {
  width: 100%;
  padding: 8px 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: white;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  cursor: pointer;
  font-size: 14px;
}

.ModelDropdown_menu__L3P8f {
  list-style: none;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  margin: 4px 0 0 0;
  padding: 0;
  background: white;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  z-index: 10;
}

.ModelDropdown_item__7cTvk {
  padding: 8px 12px;
  cursor: pointer;
  font-size: 14px;
  list-style-type: none;
}

.ModelDropdown_item__7cTvk:hover {
  background: #f8fafc;
}
`, "",{"version":3,"sources":["webpack://./src/components/dropdowns/ModelDropdown/ModelDropdown.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,iBAAiB;EACjB,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,iBAAiB;EACjB,yBAAyB;EACzB,kBAAkB;EAClB,eAAe;EACf,eAAe;AACjB;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,SAAS;EACT,OAAO;EACP,WAAW;EACX,iBAAiB;EACjB,UAAU;EACV,iBAAiB;EACjB,yBAAyB;EACzB,kBAAkB;EAClB,6CAA6C;EAC7C,WAAW;AACb;;AAEA;EACE,iBAAiB;EACjB,eAAe;EACf,eAAe;EACf,qBAAqB;AACvB;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".dropdown {\n  position: relative;\n  min-width: 200px;\n}\n\n.trigger {\n  width: 100%;\n  padding: 8px 12px;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  background: white;\n  border: 1px solid #e2e8f0;\n  border-radius: 6px;\n  cursor: pointer;\n  font-size: 14px;\n}\n\n.menu {\n  list-style: none;\n  position: absolute;\n  top: 100%;\n  left: 0;\n  width: 100%;\n  margin: 4px 0 0 0;\n  padding: 0;\n  background: white;\n  border: 1px solid #e2e8f0;\n  border-radius: 6px;\n  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);\n  z-index: 10;\n}\n\n.item {\n  padding: 8px 12px;\n  cursor: pointer;\n  font-size: 14px;\n  list-style-type: none;\n}\n\n.item:hover {\n  background: #f8fafc;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropdown": `ModelDropdown_dropdown__FKziU`,
	"trigger": `ModelDropdown_trigger__jQ-Rn`,
	"menu": `ModelDropdown_menu__L3P8f`,
	"item": `ModelDropdown_item__7cTvk`
};
export default ___CSS_LOADER_EXPORT___;
