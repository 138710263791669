import React from "react";
import styles from "./ChartBox.module.css";

interface ChartBoxProps {
  title: string;
  chart: React.ReactNode;
}

export const ChartBox: React.FC<ChartBoxProps> = ({ title, chart }) => {
  return (
    <div className={styles.box}>
      <h4>{title}</h4>
      <div className={styles.chart}>{chart}</div>
    </div>
  );
};
