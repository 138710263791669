import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_URI, AUTH_TOKEN } from "./constant/const";

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: fetchBaseQuery({
    baseUrl: API_URI,
    headers: {
      Authorization: `${AUTH_TOKEN}`,
    },
  }),
  endpoints: (build) => ({
    me: build.mutation<any, string>({
      query: () => ({
        url: "/",
        method: "GET",
      }),
    }),
  }),
});

export const { useMeMutation } = authApi;
