// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NumericChip_box__-QL3c {
  padding: 4px;
  border-radius: 50%;
  background-color: #dbeafe;
  height: 18px;
  width: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.NumericChip_text__YND\\+D {
  font-weight: 500;
  color: #6086ee;
  font-size: 14px;
}
`, "",{"version":3,"sources":["webpack://./src/components/chips/NumericChip/NumericChip.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,kBAAkB;EAClB,yBAAyB;EACzB,YAAY;EACZ,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,gBAAgB;EAChB,cAAc;EACd,eAAe;AACjB","sourcesContent":[".box {\n  padding: 4px;\n  border-radius: 50%;\n  background-color: #dbeafe;\n  height: 18px;\n  width: 18px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.text {\n  font-weight: 500;\n  color: #6086ee;\n  font-size: 14px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": `NumericChip_box__-QL3c`,
	"text": `NumericChip_text__YND+D`
};
export default ___CSS_LOADER_EXPORT___;
