export const parseAIResponse = (text: string) => {
  const jsonMatch = text.match(/```json\n([\s\S]*?)\n```/);
  let jsonString = "";

  if (jsonMatch && jsonMatch[1]) {
    jsonString = jsonMatch[1].trim();
  } else {
    const directJsonMatch = text.match(/\{[\s\S]*\}/);
    jsonString = directJsonMatch ? directJsonMatch[0] : text;
  }

  return jsonString;
};
