import React, { useState } from "react";
import { ChevronDown } from "lucide-react";
import styles from "./ModelDropdown.module.css";

interface ModelDropdownProps {
  models: any;
  selectedModel: string;
  onSelect: (model: string) => void;
}

export const ModelDropdown: React.FC<ModelDropdownProps> = ({
  models,
  selectedModel,
  onSelect,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={styles.dropdown}>
      <button className={styles.trigger} onClick={() => setIsOpen(!isOpen)}>
        {selectedModel || "Select Model"}
        <ChevronDown size={16} />
      </button>
      {isOpen && (
        <ul className={styles.menu}>
          {models?.models?.map((model: any) => (
            <li
              key={model.model}
              className={styles.item}
              onClick={() => {
                onSelect(model.model);
                setIsOpen(false);
              }}
            >
              {model.name}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
