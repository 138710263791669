export const API_URI =
  process.env.REACT_APP_API_URL || "https://4sqcapital-uat.odoo.com/v1";
export const AUTH_TOKEN =
  process.env.REACT_APP_AUTH_TOKEN ||
  "_b64c5d0a5dd13a90b1e7e9304637628c44416a93";

// export const API_URI =
//   process.env.REACT_APP_API_URL ||
//   "https://08d1-80-235-102-37.ngrok-free.app/v1";
// export const AUTH_TOKEN =
//   process.env.REACT_APP_AUTH_TOKEN ||
//   "_093fd9ba7e417c012ea6a00a4237b6a171646bca";
