export const financialHealthSeparate = (data: any) => {
  const validCategories = [
    "Accounts Payable",
    "Cash & Bank Management",
    "Accounts Receivable",
  ];

  return data
    .filter((res: any) => validCategories.includes(res.category))
    .map((res: any) => ({
      title: res.name,
      description: res.description,
      type: res.type,
      visualization: res.visualization,
      category: res.category,
      factors: res.external_factors,
      link: `/dna/${res.name}`,
    }));
};

export const operationalEfficiencySeparate = (data: any) => {
  const validCategories = ["Inventory Management"];

  return data
    .filter((res: any) => validCategories.includes(res.category))
    .map((res: any) => ({
      title: res.name,
      description: res.description,
      type: res.type,
      visualization: res.visualization,
      category: res.category,
      factors: res.external_factors,
      link: `/dna/${res.name}`,
    }));
};

export const supplyChainSeparate = (data: any) => {
  const validCategories = ["Sourcing and Procurement"];

  return data
    .filter((res: any) => validCategories.includes(res.category))
    .map((res: any) => ({
      title: res.name,
      description: res.description,
      type: res.type,
      visualization: res.visualization,
      category: res.category,
      factors: res.external_factors,
      link: `/dna/${res.name}`,
    }));
};

export const salesRevenueSeparate = (data: any) => {
  const validCategories = ["Sales Management"];

  return data
    .filter((res: any) => validCategories.includes(res.category))
    .map((res: any) => ({
      title: res.name,
      description: res.description,
      type: res.type,
      visualization: res.visualization,
      category: res.category,
      factors: res.external_factors,
      link: `/dna/${res.name}`,
    }));
};
