import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface AuthState {
  username: string | null | undefined;
}

const initialState: AuthState = {
  username: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuth(state, action: PayloadAction<{ username: string | undefined }>) {
      state.username = action.payload.username;
    },
    clearAuth(state) {
      state.username = null;
    },
  },
});

export const { setAuth, clearAuth } = authSlice.actions;
export default authSlice.reducer;
