// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SearchBar_searchBarContainer__GJV-U {
  position: relative;
  width: 100%;
}

.SearchBar_searchBar__8nNPl {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  padding: 8px;
  border: 1px solid #ebecef;
  border-radius: 8px;
  background-color: #fff;
}

.SearchBar_searchBar__8nNPl input {
  flex: 1 1;
  border: none;
  outline: none;
}

.SearchBar_results__pdxhW {
  position: sticky;
  width: 100%;
  /* padding: 0; */
  list-style: none;
  border: 1px solid #ebecef;
  border-radius: 8px;
  background-color: #fff;
}

.SearchBar_resultItem__28HkN {
  padding: 8px;
  cursor: pointer;
}

.SearchBar_resultItem__28HkN:hover {
  background-color: #f5f5f5;
}
`, "",{"version":3,"sources":["webpack://./src/components/searchbars/SearchBar/SearchBar.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,WAAW;AACb;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,QAAQ;EACR,WAAW;EACX,YAAY;EACZ,yBAAyB;EACzB,kBAAkB;EAClB,sBAAsB;AACxB;;AAEA;EACE,SAAO;EACP,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,gBAAgB;EAChB,WAAW;EACX,gBAAgB;EAChB,gBAAgB;EAChB,yBAAyB;EACzB,kBAAkB;EAClB,sBAAsB;AACxB;;AAEA;EACE,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".searchBarContainer {\n  position: relative;\n  width: 100%;\n}\n\n.searchBar {\n  display: flex;\n  align-items: center;\n  gap: 8px;\n  width: 100%;\n  padding: 8px;\n  border: 1px solid #ebecef;\n  border-radius: 8px;\n  background-color: #fff;\n}\n\n.searchBar input {\n  flex: 1;\n  border: none;\n  outline: none;\n}\n\n.results {\n  position: sticky;\n  width: 100%;\n  /* padding: 0; */\n  list-style: none;\n  border: 1px solid #ebecef;\n  border-radius: 8px;\n  background-color: #fff;\n}\n\n.resultItem {\n  padding: 8px;\n  cursor: pointer;\n}\n\n.resultItem:hover {\n  background-color: #f5f5f5;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"searchBarContainer": `SearchBar_searchBarContainer__GJV-U`,
	"searchBar": `SearchBar_searchBar__8nNPl`,
	"results": `SearchBar_results__pdxhW`,
	"resultItem": `SearchBar_resultItem__28HkN`
};
export default ___CSS_LOADER_EXPORT___;
