import React from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

interface WaterfallProps {
  data: any;
}

export const WaterfallRecharts: React.FC<WaterfallProps> = ({ data }) => {
  return (
    <ResponsiveContainer width="100%" height={400}>
      <BarChart
        data={data}
        margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Bar dataKey="pv" stackId="a" fill="transparent" />
        <Bar dataKey="uv" stackId="a">
          {data.map((item: any, index: number) => {
            if (item.uv < 0) {
              return <Cell key={index} fill="#B22222" />;
            }
            if (item.name === "Total") {
              return <Cell key={index} fill="#0000CD" />;
            }
            return <Cell key={index} fill="#228B22" />;
          })}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};
