import React from "react";
import styles from "./SearchBarBetween.module.css";
import { Search } from "lucide-react";

interface SearchBarBetweenProps {
  placeholder: string;
}

export const SearchBarBetween: React.FC<SearchBarBetweenProps> = ({
  placeholder,
}) => {
  return (
    <div className={styles.searchBar}>
      <input type="text" placeholder={placeholder} />
      <Search size={16} />
    </div>
  );
};
