import React from "react";
import styles from "./ButtonIcon.module.css";

interface ButtonIconProps {
  icon?: React.ReactNode;
  children: React.ReactNode;
}

export const ButtonIcon: React.FC<ButtonIconProps> = ({ icon, children }) => {
  return (
    <button className={styles.button}>
      {icon}
      <p className={styles.text}>{children}</p>
    </button>
  );
};
