import { Loader } from "lucide-react";
import React from "react";
import "./Loading.css";

export const Loading: React.FC = () => {
  return (
    <div className="loading-container">
      <Loader size={64} className="loader-animate" />
    </div>
  );
};
