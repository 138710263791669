import React from "react";
import styles from "./DetailBox.module.css";
import { NumericChip } from "../../chips/NumericChip/NumericChip";

interface DetailBoxProps {
  icon: React.ReactNode;
  title: string;
  description?: string;
  list: string[];
  listIcon?: React.ReactNode;
  listIconColors?: string[];
  footer?: string;
}

export const DetailBox: React.FC<DetailBoxProps> = ({
  icon,
  title,
  description,
  list,
  listIcon = "•",
  listIconColors = [],
  footer,
}) => {
  return (
    <div className={styles.box}>
      <div className={styles.header}>
        {icon}
        <h3>{title}</h3>
      </div>
      <div className={styles.boxDetails}>
        {description && <p className={styles.description}>{description}</p>}
        <ul className={styles.list}>
          {list.map((text, index) => (
            <li key={index} className={styles.listItem}>
              {listIcon && listIcon !== "numeric" ? (
                <span
                  className={styles.listIcon}
                  style={{ color: listIconColors[index] || "gray" }}
                >
                  {listIcon}
                </span>
              ) : (
                <NumericChip>{index + 1}</NumericChip>
              )}
              <span className={styles.listText}>{text}</span>
            </li>
          ))}
        </ul>
        {footer && <p className={styles.footer}>{footer}</p>}
      </div>
    </div>
  );
};
